import fort from "../../assets/img/kochi/nearby/fort kochi_13_11zon.webp";
import kochiPalace from "../../assets/img/kochi/nearby/palace.png";
import Sanctuary from "../../assets/img/kochi/nearby/sacn.png";
import Lake from "../../assets/img/kochi/nearby/lake.png";
import Marine from "../../assets/img/kochi/nearby/marine.png";
import JewTown from "../../assets/img/kochi/nearby/jew-town.png";
import Folklore from "../../assets/img/kochi/nearby/folklore.png";
import SantaCruz from "../../assets/img/kochi/nearby/santa-cruz.png";
import HillPalace from "../../assets/img/kochi/nearby/hill-palace.png";
import YellowWall from "../../assets/img/kochi/nearby/yellow-wall.png";

export const kochiNearbyContent = [
  {
    index: 0,
    title: "Fort Kochi",
    image: fort,
    description: `Known for its colonial architecture, Chinese fishing nets, and historical landmarks, 
                    Fort Kochi is a major cultural hotspot. The St. Francis Church, Mattancherry Palace, 
                    and Jew Town are some highlights.`,
    distance: "8 km",
    time: "15 minutes by car",
  },
  {
    index: 1,
    title: "Mattancherry Palace",
    image: kochiPalace,
    description: `It is a historic palace in Kochi, known for its traditional Kerala architecture, stunning 
                    murals, and collection of royal artifacts. Originally built by the Portuguese in 1555.`,
    distance: "8 km",
    time: "25 minutes by car",
  },
  {
    index: 2,
    title: "Mangalavanam Bird Sanctuary",
    image: Sanctuary,
    description: `The Mangalavanam Bird Sanctuary is a green lung space and a haven for birdwatchers. The 
                    sanctuary is home to various migratory and resident birds.`,
    distance: "1 km",
    time: "5 minutes by car",
  },
  {
    index: 3,
    title: "St. Francis Church",
    image: Lake,
    description: `St. Francis Church in Kochi is one of the oldest European churches in India, built in 1503 by Portuguese traders. It holds historical significance as the original burial site of the famous Portuguese explorer Vasco da Gama, who was later reinterred in Lisbon, Portugal. The church is an excellent example of early colonial architecture with a simple yet elegant design reflecting European influences.`,
    distance: "8 km",
    time: "25 minutes by car",
  },

  {
    index: 4,
    title: "Marine Drive",
    image: Marine,
    description: `Marine Drive is one of Kochi’s most scenic and popular waterfront promenades, offering stunning views of the backwaters and the sunset. It’s an ideal spot for a leisurely evening walk, and the nearby shopping arcades make it a favorite among locals and tourists alike.`,
    distance: "2 km",
    time: "5-10 minutes by car",
  },

  {
    index: 5,
    title: "Jew Town and Paradesi Synagogue",
    image: JewTown,
    description: `Jew Town in Mattancherry is famous for its ancient Paradesi Synagogue, one of the oldest synagogues in the Commonwealth. The narrow streets of Jew Town are lined with antique shops, spice markets, and quaint cafes, making it a cultural and historical hub.`,
    distance: "9 km",
    time: "25-30 minutes by car",
  },
  {
    index: 6,
    title: "Kerala Folklore Museum",
    image: Folklore,
    description: `The Kerala Folklore Museum is an artistic and cultural treasure trove, showcasing a wide collection of traditional Kerala art, dance costumes, masks, sculptures, and folk paintings. The museum is housed in a beautifully crafted building, reflecting Kerala’s architectural heritage.`,
    distance: "9 km",
    time: "25-30 minutes by car",
  },

  {
    index: 7,
    title: "Santa Cruz Basilica",
    image: SantaCruz,
    description: `The Santa Cruz Basilica in Fort Kochi is one of the oldest and most beautiful churches in India. Known for its Indo-European architecture, stained glass windows, and grand interiors, it is a major religious and historical attraction in Kochi.`,
    distance: "8 km",
    time: "25-30 minutes by car",
  },

  {
    index: 8,
    title: "Hill Palace Museum",
    image: HillPalace,
    description: `Hill Palace Museum is the largest archaeological museum in Kerala, located in Tripunithura. Once the royal administrative office of the Cochin Maharajas, it is now a well-preserved museum showcasing royal artifacts, including crowns, paintings, sculptures, and manuscripts. The museum is surrounded by beautiful gardens and offers insight into the region’s rich history and culture.`,
    distance: "8 km",
    time: "25-30 minutes by car",
  },

  {
    index: 9,
    title: "Kochi Yellow Wall",
    image: YellowWall,
    description: `The Yellow Wall in Kochi is a vibrant and artistic landmark located in Fort Kochi, a place known for its rich cultural history and artistic vibe. This iconic wall, painted in a bright yellow hue, has become a popular spot for photography, drawing in tourists and locals alike. It represents the city's blend of traditional and contemporary art forms, often seen as a canvas for street art and creative expressions. `,
    distance: "8 km",
    time: "25-30 minutes by car",
  },
];
