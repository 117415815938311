import React from "react";
import Brand from "../../assets/brand/brand.webp";
import Brand1 from "../../assets/brand/brand1.webp";
import Brand2 from "../../assets/brand/brand2.webp";
import Brand3 from "../../assets/brand/brand3.webp";
import Brand4 from "../../assets/brand/brand4.webp";
import Brand5 from "../../assets/brand/brand5.webp";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import { settings } from "./Slider";

const Brands = () => {
  const brands = [
    {
      name: "Eco Hotel",
      image: Brand,
    },
    {
      name: "EcoValue",
      image: Brand1,
    },
    {
      name: "The Eco Satva",
      image: Brand2,
    },
    {
      name: "EcoValue",
      image: Brand3,
    },
    {
      name: "EcoXpress Satva(Satva)",
      image: Brand4,
    },
    {
      name: "EcoXpress Satva(Satva)",
      image: Brand5,
    },
  ];

  return (
    <Slider
      className="global-slick-init attraction-slider nav-style-one nav-color-two"
      {...settings}
    >
      {brands?.map((item) => (
        <div className="guest-two-item">
          <div className="single-guest-three single-guest-two-border radius-5">
            <div className="single-guest-two-flex2">
              <div className="">
                <img src={item.image} alt="img"/>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Brands;
