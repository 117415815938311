import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomDetails: null,
  availableRooms: [],
};

const priceHotelSlice = createSlice({
  name: "priceHotel",
  initialState,
  reducers: {
    setPriceHotel: (state, action) => {
      const payload = JSON.parse(action.payload);

      if (payload.noOfRooms === 0) {
        state.roomDetails = null;
        return;
      }

      state.roomDetails = payload;

      // check if the room is already in the cart
      // const isRoomInCart = currentRoomDetails.some(
      //   (item) => item.id === payload.id
      // );

      // if (isRoomInCart) {
      //   // if the room is already in the cart, update the price and noOfRooms
      //   state.roomDetails = currentRoomDetails.map((item) =>
      //     item.id === payload.id
      //       ? { ...item, noOfRooms: payload.noOfRooms, price: payload.price }
      //       : item
      //   );
      //   return;
      // }
      // // if the room is not in the cart, add it to the cart
      // state.roomDetails = [...currentRoomDetails, { ...payload, quantity: 1 }];

      // const availableRooms = state.availableRooms;
      // const currentRoom = state.availableRooms.find(
      //   (item) => item.id === payload.roomTypeId
      // );

      // const remainingRooms = currentRoom.rooms - payload.noOfRooms;

      // state.availableRooms = availableRooms.map((item) =>
      //   item.id === payload.roomTypeId
      //     ? { ...item, rooms: remainingRooms }
      //     : item
      // );

      // minus the noOfRooms from the available rooms
      // const availableRooms = state.availableRooms;
      // const room = availableRooms.find((item) => item.id === payload.roomTypeId);

      // console.log(room, "rr")

      // const remainingRooms = room.rooms - payload.noOfRooms;
      // state.availableRooms = availableRooms.map((item) =>
      //   item.id === payload.roomTypeId ? { ...item, rooms: remainingRooms } : item
      // );
    },

    setAvailableRooms: (state, action) => {
      const rooms = action.payload;
      const currentRoomAvaibility = state.availableRooms;

      const isRoomInAvailable = currentRoomAvaibility.some(
        (item) => item.id === rooms.id
      );

      if (isRoomInAvailable) {
        state.availableRooms = currentRoomAvaibility.map((item) =>
          item.id === rooms.id ? { ...item, rooms: rooms.rooms } : item
        );
        return;
      }
      state.availableRooms.push(rooms);
    },

    clearRoomDetails: (state, action) => {
      state.roomDetails = null;
    },
  },
});

export const { setPriceHotel, setAvailableRooms, clearRoomDetails } = priceHotelSlice.actions;

export default priceHotelSlice.reducer;
