import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import WifiIcon from "@mui/icons-material/Wifi";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { LocationOn } from "@mui/icons-material";

const HotelAbout = (props) => {
  const { logo, name, address, phone, email, amenities, about, factSheet } =
    props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div>
        <Box
          sx={{
            p: 2,
          }}
        >
          {/* Hotel Name and Logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mt={2}
          >
            <Box item xs={10}>
              <Typography variant="h5" fontWeight="bold" className="jakarta">
                {name}
              </Typography>
            </Box>
            <Box item xs={2} sx={{ textAlign: "right" }}>
              <img src={logo} alt={name} style={{ width: 180 }} />
            </Box>
          </Box>

          <Stack>
            <Stack direction="row" alignItems="start" spacing={1} mt={4}>
              <LocationOn fontSize="medium" />
              <Typography variant="body">{address}</Typography>
            </Stack>
          </Stack>

          {/* Contact Information */}
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={2}
            mt={2}
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <PhoneIcon fontSize="small" />
              <Typography variant="body">{phone}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <EmailIcon fontSize="small" />
              <Typography variant="body">{email}</Typography>
            </Stack>
          </Stack>
        </Box>
        {/* Amenities */}
        <div className="p-3">
          <div className="hotel-view-contents-flex">
            <div className="hotel-view-contents-icon d-flex gap-1">
              <span>
                {" "}
                <i className="las la-parking"></i>{" "}
              </span>
              <p className="hotel-view-contents-icon-title flex-fill">
                {" "}
                Parking{" "}
              </p>
            </div>
            <div className="hotel-view-contents-icon d-flex gap-1">
              <span>
                {" "}
                <i className="las la-wifi"></i>{" "}
              </span>
              <p className="hotel-view-contents-icon-title flex-fill"> Wifi </p>
            </div>
            <div className="hotel-view-contents-icon d-flex gap-1">
              <span>
                {" "}
                <i className="las la-coffee"></i>{" "}
              </span>
              <p className="hotel-view-contents-icon-title flex-fill">
                {" "}
                Breakfast{" "}
              </p>
            </div>
            <div className="hotel-view-contents-icon d-flex gap-1">
              <span>
                {" "}
                <i className="las la-quidditch"></i>{" "}
              </span>
              <p className="hotel-view-contents-icon-title flex-fill">
                {" "}
                Room Service{" "}
              </p>
            </div>

            <div className="hotel-view-contents-icon d-flex gap-1">
              <span>
                {" "}
                <i className="las la-receipt"></i>{" "}
              </span>
              <p className="hotel-view-contents-icon-title flex-fill">
                {" "}
                Reception{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="hotel-view-contents-middle">
        <div className="hotel-view-contents-flex">
          {amenities.map((amenity, index) => (
            <div className="hotel-view-contents-icon d-flex gap-1">
              <span>
                {" "}
                <i className={`las ${amenity?.icon}`}></i>{" "}
              </span>
              <p className="hotel-view-contents-icon-title flex-fill">
                {amenity?.name}
              </p>
            </div>
          ))}
        </div>
      </div> */}

      <div className="hotel-view-contents-bottom">
        <div className="pat-30">
          <div className="d-md-flex align-items-center justify-content-between">
            <h5 className="fw-bold label">About</h5>
            <div className="text-gold bg-transparent border-0">
              <a href={factSheet?.sheet} download={factSheet?.name}>
                Download Hotel Fact Sheet
                <span className="p-1 mt-2">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1.6em"
                    width="1.6em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
          <p className="pat-5">{about}</p>
        </div>
      </div>
    </>
  );
};

export default HotelAbout;
