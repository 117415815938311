import React from "react";
import TopBanner from "./Banner";

const PrivacyPolicy = () => {
  return (
    <>
      <TopBanner />
      <div className="container my-5 px-4 px-xl-0">
        <div className="section-title center-text">
          <h2 className="title"> Privacy Policy </h2>
          <div className="section-title-shapes"></div>
        </div>
        <p>
          At EcoHotels, we value your trust and are committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information in accordance with the General Data
          Protection Regulation (GDPR). It also describes your rights regarding
          the use of your personal data.
        </p>

        <div className="section-title-three append-flex my-3 mt-5">
          <h2 className="title"> Information We Collect </h2>
          <div className="append-attraction append-color-two"></div>
        </div>
        <p>
          We collect various types of information, including:
          <ul>
            <li>
              <strong className="label">Personal Information:</strong> Name,
              email address, phone number, and other details you provide
              voluntarily when filling out forms on our website.
            </li>
            <li>
              <strong className="label">Usage Data:</strong> Information about
              how you use our website, such as your IP address, browser type,
              pages viewed, and the time spent on our website.
            </li>
            <li>
              <strong className="label">Cookies and Tracking Data:</strong>{" "}
              Cookies and similar tracking technologies to monitor your browsing
              behavior and preferences.
            </li>
          </ul>
          This data is essential to provide a seamless user experience and
          improve our services.
        </p>

        <div className="section-title-three append-flex my-3 mt-5">
          <h2 className="title"> Use of Your Information </h2>
          <div className="append-attraction append-color-two"></div>
        </div>
        <p>
          We use your personal data for the following purposes:
          <ul>
            <li>
              To provide and maintain our services, including processing your
              reservations and managing bookings.
            </li>
            <li>
              To communicate with you, sending updates, newsletters, promotions,
              and responding to inquiries or feedback.
            </li>
            <li>
              To enhance our website, ensuring it is user-friendly and tailored
              to your preferences.
            </li>
            <li>
              To comply with legal obligations and enforce our terms and
              policies.
            </li>
          </ul>
          We may also aggregate and anonymize your data to conduct research and
          improve our services without identifying any individual user.
        </p>

        <div className="section-title-three append-flex my-3 mt-5">
          <h2 className="title"> Cookies </h2>
          <div className="append-attraction append-color-two"></div>
        </div>
        <p>
          We use cookies to collect data and enhance your browsing experience.
          Cookies allow us to:
          <ul>
            <li>Remember your preferences for future visits.</li>
            <li>
              Understand how you interact with our website and which pages are
              most popular.
            </li>
            <li>Tailor content and advertisements to your interests.</li>
          </ul>
          You can disable cookies through your browser settings; however, this
          may affect the functionality of certain features on our site.
        </p>

        <div className="section-title-three append-flex my-3 mt-5">
          <h2 className="title"> Your Rights </h2>
          <div className="append-attraction append-color-two"></div>
        </div>
        <p>
          In line with GDPR, you have the following rights regarding your
          personal data:
          <ul>
            <li>
              <strong className="label">Right to Access:</strong> You can
              request a copy of the personal data we hold about you.
            </li>
            <li>
              <strong className="label">Right to Rectification:</strong> You can
              request corrections if any of your data is inaccurate or
              incomplete.
            </li>
            <li>
              <strong className="label">Right to Erasure:</strong> You have the
              right to request the deletion of your data, subject to certain
              conditions.
            </li>
            <li>
              <strong className="label">Right to Restrict Processing:</strong>{" "}
              You can ask us to stop or limit the processing of your data.
            </li>
            <li>
              <strong className="label">Right to Data Portability:</strong> You
              can request to receive your data in a machine-readable format to
              transfer it to another service provider.
            </li>
            <li>
              <strong className="label">Right to Object:</strong> You can object
              to our processing of your personal data for marketing or other
              purposes.
            </li>
          </ul>
          If you wish to exercise any of these rights, please contact us at{" "}
          <a href="mailto:reservations@ecohotels.in">reservations@ecohotels.in</a>.
        </p>

        <div className="section-title-three append-flex my-3 mt-5">
          <h2 className="title"> Contact Us</h2>
          <div className="append-attraction append-color-two"></div>
        </div>
        <p>
          If you have any questions or concerns regarding this Privacy Policy or
          how we handle your personal data, please contact us at:
          <ul>
            <li>
              <span className="label">Email:</span>
              <a href="mailto:reservations@ecohotels.in">
                reservations@ecohotels.in
              </a>
            </li>
            <li>
              <span className="label">Address:</span> SECOND FLOOR, RAJMAHAL
              BUILDING, CHURCHGATE, MUMBAI, MAHARASTRA, INDIA - 400062
            </li>
            <li>
              <span className="label">Phone:</span>+91-9004935763
            </li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
