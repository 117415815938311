import hoteld1 from "../../assets/img/hotel/hotel.png";
import hoteld2 from "../../assets/img/hotel/hotel2.png";
import hoteld3 from "../../assets/img/hotel/hotel3.png";
import summerOffer from "../../assets/img/banner/summerOffer.jpg";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import MapImage from "../../assets/img/hotel/map.png";
import SearchBox from "../Hotel/SearchBox";
import ProfileCard from "../Hotel/ProfileCard";
import tick from "../../assets/img/icons/tick.svg";
import SearchEngine from "./SearchEngine";

function HotelBooking() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const divRefs = useRef([]);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const setting2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024, // Large devices, like laptops and desktops
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium devices, like tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Small devices, like smartphones
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewSetting = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 1500,
    swipeToSlide: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
  };
  return (
    <>
      {/* <TopBanner /> */}
      <section className="hotel-details-area section-bg-2 pat-120 pab-50">
        <div className="container">
          <SearchBox />

          <div className="row g-4 pat-20">
            <div className="col-xl-9 col-lg-7">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  <div className="details-contents-header">
                    <div className="main">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider) => (sliderRef1 = slider)}
                        arrows={false}
                        className="slider slide-show"
                      >
                        <div className="bg-image radius-5">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld1}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld2}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld3}
                            alt=""
                          />
                        </div>
                      </Slider>
                      <Slider
                        asNavFor={nav1}
                        ref={(slider) => (sliderRef2 = slider)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                        {...settings}
                        className="global-slick-init attraction-slider nav-style-one nav-color-one"
                      >
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld1}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld2}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld3}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>

                  <div className="hotel-view-contents">
                    <div className="hotel-view-contents-header">
                      <h3 className="hotel-view-contents-title fw-bold">
                        Hotel Name 1
                      </h3>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          <i className="las la-map-marker-alt"></i>
                        </span>
                        <span className="hotel-view-contents-location-para">
                          4140 Parker Rd. Allentown, New Mexico 31134
                        </span>
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          <i className="las la-phone-alt"></i>
                        </span>
                        <span className="hotel-view-contents-location-para">
                          +91 4512125418, 011- 45125444
                        </span>
                        <span className="hotel-view-contents-location-icon">
                          <i className="las la-at"></i>
                        </span>
                        <span className="hotel-view-contents-location-para">
                          xyzhotel@hotel.co.in
                        </span>
                      </div>
                    </div>
                    <div className="hotel-view-contents-middle">
                      <div className="hotel-view-contents-flex">
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            <i className="las la-parking"></i>
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            Parking
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            <i className="las la-wifi"></i>
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            Wifi
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            <i className="las la-coffee"></i>
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            Breakfast
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            <i className="las la-quidditch"></i>
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            Room Service
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            <i className="las la-swimming-pool"></i>
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            Pool
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            <i className="las la-receipt"></i>
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            Reception
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            <i className="las la-dumbbell"></i>
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            Gym
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ---------------------------- Room Details with User Inputs ---------------- */}
                  <div className="container mt-4">
                    <div className="card p-3">
                      <div className="row">
                        <div className="col-md-8">
                          <h5 className="mb-3">
                            3 rooms for 3 adults, 2 children
                          </h5>
                          <p className="mb-1">
                            <strong>
                              3 ×{" "}
                              <a href="#">
                                Classic King Room with Garden View - Smoking
                              </a>
                            </strong>
                            <br />
                            Price for: <i className="bi bi-people-fill"></i>
                            <i className="bi bi-people-fill"></i>
                            <i className="bi bi-people-fill"></i>
                          </p>
                          <ul className="list-unstyled">
                            <li>
                              Bed: 1 extra-large double bed{" "}
                              <i className="bi bi-bed"></i>
                            </li>
                            <li>
                              Cot available on request{" "}
                              <i className="bi bi-baby-carriage"></i>
                            </li>
                          </ul>
                          <p className="text-success mb-1 d-flex gap-1 align-items-center">
                            <i>
                              <svg
                                class="bk-icon -streamline-checkmark_fill"
                                fill="#008009"
                                height="16"
                                width="16"
                                viewBox="0 0 128 128"
                                role="presentation"
                                aria-hidden="true"
                                focusable="false"
                              >
                                <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z"></path>
                              </svg>
                            </i>
                            Free cancellation before 29 August 2024
                          </p>
                          <p className="text-success mb-1">
                            <i className="bi bi-check-circle-fill">
                              <svg
                                class="bk-icon -streamline-checkmark_fill"
                                fill="#008009"
                                height="16"
                                width="16"
                                viewBox="0 0 128 128"
                                role="presentation"
                                aria-hidden="true"
                                focusable="false"
                              >
                                <path d="M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6.014 6.014 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a5.998 5.998 0 0 1-4.34 2.39z"></path>
                              </svg>
                            </i>{" "}
                            No prepayment needed – pay at the property
                          </p>
                          <p>
                            <i className="bi bi-x-circle-fill"></i> Breakfast ₹
                            3,175 (optional)
                          </p>
                          <p className="text-danger">
                            <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                            Only 6 rooms left on our site
                          </p>
                        </div>
                        <div className="col-md-4 text-end">
                          <p className="h5 mb-1">₹ 25,355</p>
                          <p className="mb-1">+ ₹ 5,958 taxes and charges</p>
                          <p className="mb-3">1 night, 3 adults, 2 children</p>
                          <div className="btn-wrapper">
                            <button className="cmn-btn btn-bg-1 w-100">
                              Reserve your selections
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ---------------------------- About section -------------------- */}

                    <div className="pat-30 me-5">
                      <h5 className="fw-bold label">About</h5>
                      <p className="pat-5">
                        Nestled in the heart of Kota, near City Mall and next to
                        Resonance, The Eco Satva Kota is a premium mid-range
                        hotel that combines modern luxury with a deep commitment
                        to sustainability. Boasting 63 elegantly designed rooms,
                        each equipped with energy-saving systems and
                        eco-friendly amenities, the hotel offers an unparalleled
                        experience for guests who value comfort and
                        environmental consciousness.
                        <br />
                        <br />
                        At The Eco Satva Kota, every detail is designed to
                        deliver a luxurious stay that also protects the planet,
                        making it the perfect destination for the modern,
                        mindful travele
                      </p>
                    </div>

                    <SearchEngine />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5">
              <div className="sticky-top">
                <p className="label">View in map</p>
                <img src={MapImage} alt="map" />
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10 my-4">
                  <p className="label mb-2 fw-bold">
                    Winter Wonderland Offer at The Eco Satva Kota
                  </p>
                  {/* <div className="details-sidebar">
                    <div className="details-sidebar-offer center-text radius-10">
                      <div className="details-sidebar-offer-thumb">
                        <img src={summerOffer} alt="" />
                      </div>
                      <div className="btn-wrapper mt-5">
                        <a
                          href="javascript:void(0)"
                          className="cmn-btn btn-bg-white"
                        >
                          {" "}
                          Book Now{" "}
                        </a>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="my-4">
                  <div className="bg-white radius-20 p-4">
                    <p className="label fw-bold">Review</p>
                    <Slider
                      arrows={false}
                      className="slider slide-show"
                      {...reviewSetting}
                    >
                      {[1, 2, 3, 4]?.map((i) => (
                        <ProfileCard key={i} />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HotelBooking;
