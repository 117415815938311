// create reducer to store id of hotel to be filtered
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterHotelId: [],
  filterHotelAmenities: [],
};

const filterHotelSlice = createSlice({
  name: "filterHotel",
  initialState,
  reducers: {
    setFilterHotelId: (state, action) => {
      const hotelId = action.payload;
      const index = state.filterHotelId.indexOf(hotelId);
      if (index === -1) {
        state.filterHotelId.push(hotelId);
      } else {
        state.filterHotelId.splice(index, 1);
      }
    },

    setFilterHotelAmenities: (state, action) => {
      const amenities = action.payload;
      const index = state.filterHotelAmenities.indexOf(amenities);
      if (index === -1) {
        state.filterHotelAmenities.push(amenities);
      } else {
        state.filterHotelAmenities.splice(index, 1);
      }
    },
  },
});

export const { setFilterHotelId, setFilterHotelAmenities } = filterHotelSlice.actions;

export default filterHotelSlice.reducer;
