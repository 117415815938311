import living from "../assets/img/hotel/bed-1.png";
import dining from "../assets/img/hotel/dining.jpeg";
import summerOffer from "../assets/img/events/event-menu-2.png";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import HotelTab from "./Hotel/HotelTab";
import MapImage from "../assets/img/hotel/map.png";
import SearchBox from "./Hotel/SearchBox";
import ProfileCard from "./Hotel/ProfileCard";
import { Link } from "react-router-dom";
import EcoLogo from "../assets/brand/brand3.webp";
import nearby1 from "../assets/img/nagpur/nearby1.webp";
import nearby2 from "../assets/img/nagpur/nearby2.png";
import nearby3 from "../assets/img/nagpur/nearby3.webp";
import nearby4 from "../assets/img/nagpur/nearby4.webp";
import { LivingContent } from "./content/EcoHotel";
import {
  ggContent,
  nagpurHotelImageList,
  saharContent,
  kickInTheBrickContent,
} from "./content/NagpurDetails";
import NagpurFactSheetPdf from "../assets/nagpurfactsheet.pdf";
import GuestReviews from "./Hotel/GuestReview";
import HotelPageBuilder from "./ui/HotelPageBuilder";
import { useMediaQuery, useTheme } from "@mui/material";

const livingData = [
  {
    tab: "Overview",
    content:
      "At EcoXpress Satva, our accommodations are thoughtfully designed to provide the ultimate comfort and relaxation for all guests. Each room features contemporary decor, plush bedding, and a serene ambiance, ensuring a restful stay. Modern amenities, including flat-screen TVs, high-speed Wi-Fi, and spacious work areas, cater to both leisure and business travelers. Large windows invite natural light and offer views of the vibrant Nagpur cityscape. En-suite bathrooms come equipped with premium toiletries and modern fixtures, enhancing the overall experience.",
  },
  {
    tab: "Deluxe Room",
    content: LivingContent(
      " Our Deluxe Rooms offer a perfect balance of comfort and style. Designed for relaxation, these rooms feature elegant furnishings, a cozy seating area, and essential amenities. Ideal for couples or solo travelers, the Deluxe Rooms create a welcoming environment for unwinding after a day of exploration.",
      7999
    ),
  },
  {
    tab: "Executive Room",
    content: LivingContent(
      "The Executive Rooms elevate the guest experience with added space and luxury. Tailored for business travelers, these rooms include upgraded furnishings, a dedicated workspace, and enhanced amenities making them ideal for those who seek both comfort and sophistication during their stay.",
      7999
    ),
  },
];

const diningDetails = [
  {
    tab: "Overview",
    content: (
      <p>
        At EcoXpress Satva Nagpur, the dining experience is crafted to provide a
        unique blend of sustainability, local flavors, and a touch of elegance,
        all within a modern and eco-conscious environment. The hotel offers two
        distinct dining spaces that cater to diverse tastes: a rooftop bar and a
        main restaurant. Both dining venues prioritize sustainability, using
        locally sourced ingredients and eco-friendly practices in their
        operations.
      </p>
    ),
  },
  {
    tab: "GG's",
    content: ggContent,
  },
  {
    tab: "SAHAR",
    content: saharContent,
  },
  {
    tab: "Kick in the Brick",
    content: kickInTheBrickContent,
  },
];

const aboutHotel = (
  <p>
    Welcome to EcoXpress Satva, a serene 44-room hotel located in the bustling
    heart of Nagpur. Designed for travelers and locals alike, our hotel offers a
    unique blend of comfort and sustainability, featuring an exclusively
    vegetarian restaurant and bar.
    <br />
    <br />
    At EcoXpress Satva, our stylishly appointed rooms provide a peaceful escape,
    complete with modern amenities and thoughtful touches to ensure a restful
    stay. Our signature “Sahar- A Satvik Restaurant” serves an array of
    delectable dishes, showcasing the best of Indian and international cuisines.
    Each meal is crafted with fresh, locally sourced ingredients, ensuring a
    delightful dining experience for all guests.
    <br />
    <br />
    Complementing the restaurant, our bar offers an innovative selection of
    cocktails, mocktails, fresh juices, and herbal infusions, perfect for
    unwinding after a day of exploration. Whether you're visiting for business
    or leisure, EcoXpress Satva is your ideal destination in Nagpur, where
    hospitality meets a commitment to vegetarian values. Experience comfort,
    flavor, and tranquility, all in one place!
  </p>
);

function NagpurHotelDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sections = [
    {
      id: 1,
      label: "Living",
      image: living,
      type: "living",
      data: livingData,
      style: isMobile ? "rounded" : "oval",
    },
    {
      id: 2,
      label: "Dining",
      image: dining,
      type: "dining",
      data: diningDetails,
    },
    { id: 4, label: "Nearby Attractions" },
  ];

  const attractions = [
    {
      name: "Dragon Palace Temple",
      location: "Nagpur",
      image: nearby1,
    },
    {
      name: "Sitabuldi Fort",
      location: "Nagpur",
      image: nearby2,
    },
    {
      name: "Ambazari Lake",
      location: "Nagpur",
      image: nearby3,
    },
    {
      name: "Deekashaboomi",
      location: "Nagpur",
      image: nearby4,
    },
  ];

  return (
    <HotelPageBuilder
      hotelName="EcoXpress Satva Nagpur"
      logo={EcoLogo}
      address="Building No: 25, Central Avenue Road, Nagpur - 440008"
      phone="+91 8126843876"
      email="reservations@ecohotels.in"
      amenities={[]}
      aboutHotel={aboutHotel}
      sections={sections}
      coverImages={nagpurHotelImageList}
      factSheet={{}}
      nearby={attractions}
      nearbyLink="/NagpurNearbyAttraction"
    />
  );
}

export default NagpurHotelDetails;
