import hoteld1 from "../assets/img/sindhudurg/hotel/hotel1.webp";
import hoteld2 from "../assets/img/sindhudurg/hotel/hotel2.webp";
import hoteld3 from "../assets/img/kota/room1.jpeg";
import living from "../assets/img/hotel/bed-1.png";
import dining from "../assets/img/hotel/dining.jpeg";
import attraction from "../assets/img/hotel/attraction.png";
import summerOffer from "../assets/img/events/event-menu.png";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import HotelTab from "./Hotel/HotelTab";
import MapImage from "../assets/img/hotel/map.png";
import SearchBox from "./Hotel/SearchBox";
import ProfileCard from "./Hotel/ProfileCard";
import { Link } from "react-router-dom";
import EcoLogo from "../assets/img/single-page/EHRL-4.webp";
import nearby1 from "../assets/img/sindhudurg/nearby1.png";
import nearby2 from "../assets/img/sindhudurg/nearby2.png";
import nearby3 from "../assets/img/sindhudurg/nearby3.png";
import nearby4 from "../assets/img/sindhudurg/nearby4.png";
import { LivingContent } from "./content/EcoHotel";
import { diningBarMenu, diningRestaurant } from "./content/SindhudurgHotel";
import { sindhudurgHotelImageList } from "./content/SindhudurgHotel";

const livingData = [
  {
    tab: "Overview",
    content: (
      <p>
        Eco Satva (Xpress) Sindhudurg offers 12 elegantly designed rooms that
        prioritize comfort and sustainability. Each room is furnished with
        eco-conscious materials and energy-saving amenities to ensure a relaxing
        stay.
      </p>
    ),
  },
  {
    tab: "Standard Double Room",
    content: LivingContent(
      "The Double Standard Rooms at The Eco Satva Xpress Sindhudurg are designed to offer comfort, modern amenities, and eco-sensitivity. Each room is spacious, with minimalist décor that emphasizes sustainable materials and local craftsmanship.",
      4999
    ),
  },
  // {
  //   tab: "Executive Suite",
  //   content: LivingContent(
  //     "Suites at The Eco Sindhudurg offer a luxurious and spacious retreat with separate living and sleeping areas. Ideal for guests seeking the ultimate in comfort and exclusivity",
  //     10000
  //   ),
  // },
];

const diningData = [
  {
    tab: "Overview",
    content: (
      <p>
        At EcoXpress SatvaSindhudurg, the GG Rooftop Restobar and Darbar Restaurant
        offer distinct yet complementary dining experiences. GG Rooftop Restobar
        provides a chic, open-air atmosphere with stunning views of the
        cityline, perfect for unwinding with signature mocktails and light
        bites. Meanwhile, Darbar, the hotel's main restaurant, serves a mix of
        traditional Malvani and global cuisine with a focus on fresh,
        locally-sourced ingredients.
      </p>
    ),
  },
  {
    tab: "GG's",
    content: diningBarMenu,
  },
  {
    tab: "DARBAR",
    content: diningRestaurant,
  },
];

const eventData = (
  <>
    <p>
      Eco Sindhudurg features a well-appointed banquet hall designed to host a
      variety of events, from intimate weddings to corporate gatherings. The
      hall is equipped with modern facilities and focuses on sustainable event
      planning.
    </p>
    <br />
    <h6 className="label">The Grand Hall</h6>
    <p>Capacity: Up to 300 guests</p>
    <p>Price: ₹60,000 per day </p>
    <p>Features & Amenities:</p>
    <div className="d-flex justify-content-between align-items-end">
      <ul>
        <li>Elegant design</li>
        <li>Customizable Layouts</li>
        <li>Sustainable Decor Options</li>
        <li>Climate Control</li>
      </ul>
      <div className="btn-wrapper pab-20 par-20">
        <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
      </div>
    </div>
  </>
);

function SindhudurgHotelDetails() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const divRefs = useRef([]);

  const sections = [
    { id: 1, label: "Living", image: living, type: "living", data: livingData },
    { id: 2, label: "Dining", image: dining, type: "dining", data: diningData },
    // {
    //   id: 3,
    //   label: "Event",
    //   image: attraction,
    //   type: "event",
    //   data: [{ content: eventData }],
    // },
    { id: 3, label: "Nearby Attractions" },
  ];

  // Function to scroll to a specific div
  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const setting2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
    responsive: [
      {
        breakpoint: 1024, // Large devices, like laptops and desktops
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium devices, like tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Small devices, like smartphones
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewSetting = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 1500,
    swipeToSlide: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
  };
  return (
    <>
      {/* <TopBanner /> */}
      <section className="hotel-details-area section-bg-2 pat-120 pab-50">
        <div className="container">
          <SearchBox />

          <div className="row g-4 pat-20">
            <div className="col-xl-9 col-lg-7">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  <div className="details-contents-header">
                    <div className="main">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider) => (sliderRef1 = slider)}
                        arrows={false}
                        className="slider slide-show"
                      >
                        {sindhudurgHotelImageList.map((hotel) => (
                          <div key={hotel.id} className="bg-image radius-5">
                            <img
                              className="img-fluid radius-5"
                              style={{ minWidth: "100%", minHeight: "100%" }}
                              src={hotel.image}
                              alt={`hotel-${hotel.id}`}
                            />
                          </div>
                        ))}
                      </Slider>

                      <Slider
                        asNavFor={nav1}
                        ref={(slider) => (sliderRef2 = slider)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                        {...setting2}
                        className="global-slick-init attraction-slider nav-style-one nav-color-one"
                      >
                        {sindhudurgHotelImageList.map((hotel) => (
                          <div
                            key={hotel.id}
                            className="custom-details-contents-header-thumb"
                          >
                            <img
                              src={hotel.image}
                              alt={`thumb-hotel-${hotel.id}`}
                              style={{
                                minWidth: "100%",
                                minHeight: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>

                  <div className="hotel-view-contents">
                    <div className="hotel-view-contents-header">
                      <div className="d-flex align-items-center justify-content-between par-20">
                        <h3 className="fw-bold ">EcoXpress Sindhudurg</h3>
                        <img src={EcoLogo} alt="Eco Logo" width={150} />
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-map-marker-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          Siddhi Cinema, 155/2, Opp. RTO Office
                        </span>
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-phone-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          +91 9004935763
                        </span>
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-at"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          reservations@ecohotels.in
                        </span>
                      </div>
                    </div>
                    <div className="hotel-view-contents-middle">
                      <div className="hotel-view-contents-flex">
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-parking"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Parking{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-wifi"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Wifi{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-coffee"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Breakfast{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-quidditch"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Room Service{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-swimming-pool"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Pool{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-receipt"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Reception{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-dumbbell"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Gym{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="hotel-view-contents-bottom">
                      <div className="pat-30 par-20">
                        <h5 className="fw-bold label">About</h5>
                        <p className="pat-5">
                          Eco Hotels and Resorts Ltd is committed to bringing
                          the highly rich yet unexplored Konkan region to
                          India’s tourist and cultural map with the opening of
                          three Hotels in Sindhudurg in a phased manner. The
                          first hotel which is a boutique hotel is going to be
                          opened in —-October at Orus, the administrative
                          capital of Sindhudurg. Located in the heart of
                          Maharashtra’s Konkan region, EcoXpress Sindhudurg
                          offers a unique blend of luxury, sustainability, and
                          natural beauty. With its eco-friendly design and focus
                          on sustainability, the hotel provides an intimate and
                          luxurious experience with just 12 premium rooms, a
                          spacious banquet hall for events, and a rooftop bar
                          and restaurant offering stunning views of the
                          surrounding landscapes
                        </p>
                      </div>
                      {/* Top Navigation */}
                      <ul className="nav nav-pills my-4 gap-4 d-flex  justify-content-sm-center">
                        {sections?.map((item, index) => (
                          <li className="nav-item" key={item?.id}>
                            <div className="">
                              <span
                                className={`hotel-btn-wrapper hotel-btn ${
                                  index === 0 ? "active" : ""
                                }`}
                                onClick={() => scrollToDiv(index)}
                              >
                                {item?.label}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {sections
                        .map((section, index) => (
                          <div
                            key={section.id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            <HotelTab
                              title={section.label}
                              image={section.image}
                              type={section.type}
                              tab={true}
                              data={section.data}
                              downloadMenuBtn={section.id === 2}
                            />
                          </div>
                        ))
                        ?.slice(0, 2)}

                      {/* <HotelTab title="Dining" image={dining} type={"dining"} />
                      <HotelTab title="Events" image={attraction} /> */}
                      <section
                        className="attraction-area"
                        ref={(el) => (divRefs.current[3] = el)}
                      >
                        <div className="">
                          <div className="row my-5">
                            <h4
                              className="hotel-view-contents-bottom-title"
                              style={{
                                fontSize: "18px",
                              }}
                            >
                              Nearby Attractions
                            </h4>
                            <div className="col-12 mt-3">
                              <Slider
                                className="global-slick-init attraction-slider nav-style-one nav-color-two"
                                {...setting2}
                              >
                                <div>
                                  <Link to="/SindhudurgNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{ width: "90%" }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby1} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Forts
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/SindhudurgNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby2} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Beaches
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/SindhudurgNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby3} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Temples
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/SindhudurgNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby4} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Other Attractions
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-dropdown custom-form">
                      <form action="#">
                        <div className="single-input">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check In{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check in"
                            value="06-08-2024 12:00"
                          />
                          <input
                            className="form--control date-picker form-control input"
                            placeholder="Check in"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          />
                        </div>
                        <div className="single-input mt-3">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check Out{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check out"
                            value="07-08-2024 12:00"
                          />
                          <input
                            className="form--control date-picker form-control input"
                            placeholder="Check out"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="details-sidebar-quantity pt-4">
                      <div className="details-sidebar-quantity-flex">
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Guest{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="5"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Children{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="3"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Room{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="2"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper mt-4">
                      <Link
                        to="/Checkout"
                        className="cmn-btn btn-bg-1 btn-small"
                      >
                        {" "}
                        Reserve Now{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <p className="label">View in map</p>
                <img src={MapImage} alt="map" />

                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10 my-4">
                  <p className="label mb-2 fw-bold">
                    Celebrate Sustainably, Host Elegantly – Book Your
                    Eco-Friendly Event at The Eco Satva
                  </p>
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={summerOffer} alt="" />
                    </div>
                    <div className="btn-wrapper mt-3">
                      <Link to="/events" className="cmn-btn btn-bg-1 w-100">
                        {" "}
                        Book Now{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <div className="bg-white radius-20 p-4 pb-5">
                    <p className="label fw-bold">Review</p>
                    <Slider
                      arrows={false}
                      className="slider slide-show"
                      {...reviewSetting}
                    >
                      {[1, 2, 3, 4]?.map((i) => (
                        <ProfileCard key={i} />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SindhudurgHotelDetails;
