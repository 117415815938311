import React, { useEffect, useRef, useState } from "react";
import SearchBox from "../SearchBox";
import Slider from "react-slick";
import hoteld1 from "../../../assets/img/kochi/nearby/nearby1.webp";
import hoteld2 from "../../../assets/img/kochi/nearby/nearby2.webp";
import hoteld3 from "../../../assets/img/kochi/nearby/nearby3.png";
import hoteld4 from "../../../assets/img/kochi/nearby/nearby4.webp";
import food1 from "../../../assets/img/kochi/nearby/food1.png";
import food2 from "../../../assets/img/kochi/nearby/food2.png";
import food3 from "../../../assets/img/kochi/nearby/food3.png";
import food4 from "../../../assets/img/kochi/nearby/food4.png";
import culture from "../../../assets/img/kochi/nearby/Kochi culture (1)_8_11zon.webp";
import fort from "../../../assets/img/kochi/nearby/fort kochi_13_11zon.webp";
import travel from "../../../assets/img/kochi/nearby/travel.png";
import kochiPalace from "../../../assets/img/kochi/nearby/palace.png";
import Sanctuary from "../../../assets/img/kochi/nearby/sacn.png";
import Lake from "../../../assets/img/kochi/nearby/lake.png";
import sunnySvg from "../../../assets/img/hotel/sunny.svg";
import rainySvg from "../../../assets/img/hotel/rainy.svg";
import snowySvg from "../../../assets/img/hotel/weather_snowy.png";
import weather from "../../../assets/img/kota/nearby/weather.png";
import { kochiNearbyContent } from "../../content/KochiNearbyContent";
import Marine from "../../../assets/img/kochi/nearby/marine.png";
import JewTown from "../../../assets/img/kochi/nearby/jew-town.png";
import Folklore from "../../../assets/img/kochi/nearby/folklore.png";
import HotelTab from "../HotelTab";
import AttractionSection from "../ArractionSection";
import { settings1 } from "../../Home/Slider";
import {
  Typography,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";

const cultureContent = (
  <>
    <p>
      Kochi's culture is a fascinating blend of various traditions and
      influences, reflecting its long history as a trade hub. The city has a
      rich heritage, with influences from the Portuguese, Dutch, and British
      colonial periods, as well as its ancient maritime trade connections with
      the Middle East and China. The culture is evident in its art forms,
      cuisine, and festivals.
    </p>
    <br />
    <p>
      The population of Kochi is estimated to be around 700,000 within the city
      limits, with the larger metropolitan area housing over 2.1 million people.
      The city is cosmopolitan, with people from various parts of India and the
      world, contributing to its diverse demographic makeup.
    </p>
  </>
);

const travelContent = (
  <>
    <ul className="d-flex flex-column gap-4">
      <li>
        <span className="label">By Air:</span>Cochin International Airport
        (COK), located about 33.5 km from the hotel, is the nearest airport and
        is well-connected to major cities in India and abroad.
      </li>
      <li>
        <span className="label">By Train:</span>Kochi has two main railway
        stations—Ernakulam Junction and Ernakulam Town—which are well-connected
        to major Indian cities.
      </li>
      <li>
        <span className="label">By Road:</span>Kochi is connected by a network
        of national and state highways. The KSRTC (Kerala State Road Transport
        Corporation) and private buses operate frequent services to nearby
        cities like Trivandrum, Bangalore, and Coimbatore.
      </li>
    </ul>
  </>
);

const KochiNearbyAttraction = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    scrollToDiv(newValue);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const divRefs = useRef([]);

  const sections = [
    {
      id: 2,
      label: "Cuture",
      image: culture,
      type: "dining",
      data: [{ content: cultureContent }],
    },
    {
      id: 3,
      label: "Travel",
      image: travel,
      type: "event",
      data: [{ content: travelContent }],
    },
    { id: 4, label: "Food" },
  ];

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="hotel-details-area section-bg-2 pat-120 pab-50">
      <div className="container">
        <SearchBox />
        <div className="details-contents bg-white radius-10 mt-4">
          <div className="details-contents-header">
            <div className="main">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                arrows={false}
                className="slider slide-show"
              >
                <div className="bg-image radius-5">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld1}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld2}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld3}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld4}
                    alt=""
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                {...settings1}
                className="global-slick-init attraction-slider nav-style-one nav-color-one"
              >
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld1}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld2}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld3}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld4}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Slider>
            </div>

            <div className="hotel-view-contents mt-5">
              <Box p={2}>
                <Typography variant="h5" fontWeight="bold">
                  Explore the Vibrant Kochi,Kerala
                </Typography>

                <Box mt={2} textAlign={"left"}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="text.secondary"
                  >
                    Best Time to Visit Kochi
                  </Typography>
                  <Box
                    mt={1}
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    gap={1}
                  >
                    <img
                      src={weather}
                      alt="sunny"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1">October - February</Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="center"
                  gap={isMobile ? 2 : 5}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={sunnySvg}
                      alt="sunny"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Apr - Jun (35°C to 45°C)
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={rainySvg}
                      alt="rainy"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Jul - Sep (25°C to 35°C)
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={snowySvg}
                      alt="snowy"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Oct - Mar (10°C to 25°C)
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ mt: 2 }} />
              </Box>

              <div className="hotel-view-contents-bottom">
                <div className="pat-30 par-20">
                  <h5 className="fw-bold label">About</h5>
                  <p className="pat-5">
                    Kochi, also known as Cochin, is a vibrant port city located
                    on the southwest coast of India in Kerala. It is a blend of
                    modernity and tradition, known for its rich cultural
                    history, colonial architecture, and natural beauty. Often
                    referred to as the "Queen of the Arabian Sea," Kochi has
                    been a key trading port since ancient times, attracting
                    traders from Arabia, China, and Europe. The city is a
                    melting pot of cultures and communities, including Hindus,
                    Muslims, Christians, and Jews, which has given rise to a
                    unique and diverse cultural landscape.
                  </p>
                </div>
                {/* Top Navigation */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={isMobile ? "start" : "center"}
                  my={4}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{ hidden: true }}
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        gap: { sm: 5 },
                      },
                      "& .MuiTabs-scrollButtons": {
                        display: "flex",
                      },
                      "@media (max-width: 600px)": {
                        "& .MuiTabs-scrollButtons.Mui-disabled": {
                          display: "flex",
                        },
                      },

                      "& .MuiTabs-scrollButtons.Mui-disabled": {
                        display: "flex",
                        opacity: 0.5,
                      },
                    }}
                  >
                    {sections?.map((item, index) => (
                      <Tab
                        key={item.id}
                        label={item.label}
                        onClick={() => scrollToDiv(index)}
                        sx={{
                          borderRadius: "50px",
                          backgroundColor: "transparent",
                          color: "inherit",
                          padding: "8px 36px",
                          minWidth: "auto",
                          transition: "all 0.3s ease",
                          "&.Mui-selected": {
                            border: "1px solid #8b4513",
                            color: "black",
                          },
                          "&:hover": {
                            border: "1px solid #8b4513",
                            color: "black",
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                </Box>
                <div>
                  {sections
                    .map((section, index) => (
                      <div
                        key={section.id}
                        ref={(el) => (divRefs.current[index] = el)}
                      >
                        <HotelTab
                          title={section.label}
                          image={section.image}
                          type={section.type}
                          data={section.data}
                        />
                      </div>
                    ))
                    ?.slice(0, 2)}
                  <div ref={(el) => (divRefs.current[2] = el)}>
                    <h4
                      className="hotel-view-contents-bottom-title"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Food in Kochi
                    </h4>
                    <p>
                      Kochi offers a wide variety of delicious cuisines,
                      reflecting its multicultural influences. Kerala cuisine,
                      with its liberal use of coconut, curry leaves, and spices,
                      is the highlight, but the city also offers international
                      cuisines due to its cosmopolitan population.:
                    </p>

                    <div className="pat-10">
                      <div className="row g-4">
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food1}
                                    alt="Appam with Stew"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Appam with Stew</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food2}
                                    alt="Meen Moilee"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Meen Moilee</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food3}
                                    alt="Parotha with Beef Fry"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">
                                    Parotha with Beef Fry
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food4}
                                    alt="puttu"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">
                                    Puttu and Kadala Curry
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <h4
                        className="hotel-view-contents-bottom-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Top Attractions Near The EcoValue Kochi
                      </h4>

                      <div className="pat-40">
                        {kochiNearbyContent?.map((attraction, idx) => (
                          <AttractionSection
                            key={idx}
                            index={attraction.index}
                            title={attraction.title}
                            image={attraction.image}
                            description={attraction.description}
                            distance={attraction.distance}
                            time={attraction.time}
                            isReverse={idx % 2 !== 0}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KochiNearbyAttraction;
