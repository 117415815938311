import Deekshabhoomi from "../../assets/img/nagpur/nearby/deekshabhoomi.webp";
import Ambazari from "../../assets/img/nagpur/nearby/ambazari.webp";
import Sitabuldi from "../../assets/img/nagpur/nearby/sitabuli.webp";
import Maharajbagh from "../../assets/img/nagpur/nearby/maharajbagh.webp";
import Dragon from "../../assets/img/nagpur/nearby/dragon.webp";
import Raman from "../../assets/img/nagpur/nearby/raman.webp";
import Zero from "../../assets/img/nagpur/nearby/zero.webp";
import SeminaryHill from "../../assets/img/nagpur/nearby/seminary.webp";
import Sonegaon from "../../assets/img/nagpur/nearby/sonegaon.webp";
import Ramtek from "../../assets/img/nagpur/nearby/ramtek.webp";
import Japanese from "../../assets/img/nagpur/nearby/japanese-rose.webp";
import Telankhedi from "../../assets/img/nagpur/nearby/telekhedi.webp";

export const NagpurNearbyContent = [
  {
    title: "Deekshabhoomi",
    image: Deekshabhoomi,
    description:
      "Deekshabhoomi is a prominent Buddhist monument where Dr. B.R. Ambedkar embraced Buddhism in 1956, making it a significant pilgrimage site. The grand stupa here is a symbol of equality and peace, attracting millions of visitors annually, especially during Dhamma Chakra Pravartan Din.",
    distance: "6 km",
    time: "20 minutes by car",
  },
  {
    title: "Ambazari Lake and Garden",
    image: Ambazari,
    description:
      "A serene lake surrounded by lush gardens, Ambazari is perfect for a family outing or a relaxing evening. You can enjoy boating and nature walks while soaking in the beautiful sunset views. The gardens are well-maintained, making it an ideal spot for picnics.",
    distance: "9 km",
    time: "25 minutes by car",
  },
  {
    title: "Sitabuldi Fort",
    image: Sitabuldi,
    description:
      "This historic fort, located on a hilltop, played a key role in Nagpur's colonial history. It offers panoramic views of the city and is a quiet spot for history enthusiasts. The fort is open to the public on special occasions.",
    distance: "5 km",
    time: "15 minutes by car",
  },

  {
    title: "Dragon Palace Temple",
    image: Dragon,
    description:
      "A stunning Buddhist temple known for its architectural beauty and peaceful ambiance. The temple is set in a lush green area and is an ideal spot for meditation and spiritual retreats.",
    distance: "20 km",
    time: "40 minutes by car",
  },

  {
    title: "Seminary Hill",
    image: SeminaryHill,
    description:
      "Seminary Hill is a picturesque and tranquil hill in Nagpur, offering stunning views of the city and lush greenery. It’s a popular spot for nature lovers and hikers, providing a peaceful retreat from the city's hustle and bustle. The area is named after the Catholic seminary located here, and the hill is known for its serene atmosphere and panoramic vistas.",
    distance: "8 km",
    time: "20 minutes by car",
  },

  {
    title: "Sonegaon Lake",
    image: Sonegaon,
    description:
      "Sonegaon Lake is a scenic and tranquil lake located in Nagpur, offering a peaceful environment for relaxation and leisure. Surrounded by greenery, it’s a popular spot for locals and visitors alike to enjoy nature, birdwatching, and evening walks. The lake is especially beautiful during the monsoon season when it fills up, creating a picturesque landscape.",
    distance: "10 km",
    time: "30 minutes by car",
  },

  {
    title: "Ramtek Fort Temple",
    image: Ramtek,
    description:
      "Ramtek Fort Temple is a historic and sacred site located on the Ramgiri Hill near Nagpur. This ancient temple is dedicated to Lord Rama, and it is believed that he rested here during his exile. The fort and temple offer stunning views of the surrounding landscapes, and the location is rich in mythological and historical significance, making it a popular pilgrimage site.",
    distance: "50 km",
    time: "1.5 hours by car",
  },

  {
    title: "Japanese Rose Garden",
    image: Japanese,
    description:
      "Japanese Rose Garden is a serene and beautifully landscaped garden located in Nagpur, known for its vibrant collection of roses and peaceful ambiance. It's a perfect spot for nature lovers, offering well-maintained pathways, seating areas, and colorful rose varieties. The garden provides a tranquil escape within the city, ideal for a leisurely stroll or relaxation.",
    distance: "5 km",
    time: "15 minutes by car",
  },

  {
    title: "Telekhedi Hanuman Temple",
    image: Telankhedi,
    description:
      "Telankhedi Hanuman Temple is a revered temple located in Nagpur, dedicated to Lord Hanuman. Nestled near Telankhedi Lake, it offers a serene environment for worship and contemplation. The temple is frequented by devotees, especially on Tuesdays and Saturdays, and is known for its beautiful architecture and peaceful surroundings. The combination of the temple and the lake makes it a popular destination for spiritual seekers and nature enthusiasts alike.",
    distance: "5 km",
    time: "15 minutes by car",
  },

  {
    title: "Zero Mile Marker",
    image: Zero,
    description:
      "A historical landmark representing the geographical center of colonial India, Zero Mile is marked by a stone pillar. It is of great geographical and historical importance, especially for map enthusiasts.",
    distance: "3 km",
    time: "10 minutes by car",
  },

  {
    title: "The Raman Science Centre",
    image: Raman,
    description:
      "The Raman Science Centre, named after Nobel laureate C.V. Raman, is a hub for science enthusiasts and educational visitors. It is designed to promote scientific temper among students and the general public. The interactive exhibits focus on various scientific principles across physics, chemistry, and biology, making it a fun and engaging experience for all age groups.",
    distance: "3.5 km",
    time: "10 minutes by car",
  },

  {
    title: "Maharajbagh Zoo",
    image: Maharajbagh,
    description:
      "Originally a royal garden, this zoo houses a variety of animals and birds. It’s popular among families and children and is a great place to learn about local wildlife while enjoying the greenery.",
    distance: "4 km",
    time: "15 minutes by car",
  },
];
