import React from "react";

const ProfileCard = ({image, name, content}) => {
  return (
    <div className="card-container">
      <img
        src={image}
        alt="Profile"
        className="profile-image"
      />
      <h3 className="profile-name ">{name}</h3>
      <p className="profile-description verdana">
        {content}
      </p>
    </div>
  );
};

export default ProfileCard;
