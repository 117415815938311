import { Link } from "react-router-dom";
import Hotel1 from "../../assets/img/nagpur/hotel1.jpg";
import Hotel2 from "../../assets/img/nagpur/hotel2.png";
import Hotel3 from "../../assets/img/nagpur/hotel3.png";
import Hotel4 from "../../assets/img/nagpur/hotel2.jpg";
// import Hotel4 from "../../assets/img/nagpur/hotel4.png";

export const nagpurHotelImageList = [
  { id: 1, img: Hotel1 },
  { id: 2, img: Hotel4 },
  { id: 3, img: Hotel2 },
  { id: 4, img: Hotel3 },
  // { id: 4, image: Hotel4 },
];

export const ggContent = (
  <>
    <p>
      Experience breathtaking rooftop dining at GG Restro Bar, where stunning
      city views meet a diverse menu of Pan-Asian Cuisine. Enjoy craft cocktails,
      wines, and artisanal beverages in a relaxed yet sophisticated atmosphere.
      With cozy seating, stylish decor, and live music, it’s the perfect spot
      for unwinding or celebrating special moments. Join us for an unforgettable
      culinary adventure!
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-4 col-md-2">
        <p className="label">Everyday:</p>
      </div>
      <div className="col-7 col-md-6 ">
        <p className="text-gold">5:00 PM – 11:00 PM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        {/* <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link> */}
      </div>
    </div>
  </>
);

export const saharContent = (
  <>
    <p>
      Sahar, is a charming vegetarian restaurant offering a diverse menu of
      traditional Indian and contemporary global dishes made with fresh, locally
      sourced ingredients. With a warm ambiance, it's an ideal spot for family
      gatherings and casual meet-ups, celebrating the vibrant flavours of
      vegetarian cuisine in a community-focused space.
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-4 col-md-2">
        <p className="label">Breakfast:</p>
        <p className="label">Lunch:</p>
        <p className="label">Dinner:</p>
      </div>
      <div className="col-8 col-md-6 ">
        <p className="text-gold">7:30 AM – 10:30 AM</p>
        <p className="text-gold">12:30 PM – 3:00 PM</p>
        <p className="text-gold">7:30 PM – 10:30 PM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        {/* <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link> */}
      </div>
    </div>
  </>
);

export const kickInTheBrickContent = (
  <>
    <p>
      Kick in the Brick is Nagpur&apos;s premier basement pub. Enjoy a diverse
      menu of craft beers, signature cocktails, and delicious bites. With
      stylish decor, cozy seating, and live music, it&apos;s the perfect spot
      for celebrations or unwinding with friends. Join us for an unforgettable
      pub and brewery experience!
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-4 col-md-2">
        <p className="label">Everyday:</p>
      </div>
      <div className="col-7 col-md-6 ">
        <p className="text-gold">5:00 PM – 12:00 AM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        {/* <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link> */}
      </div>
    </div>
  </>
);
