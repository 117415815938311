import { createSlice } from "@reduxjs/toolkit";

const bookingInitialState = (() => {
  const savedState = sessionStorage.getItem("bookingData");
  return savedState ? JSON.parse(savedState) : {};
})();

const bookingSlice = createSlice({
  name: "booking",
  initialState: bookingInitialState,
  reducers: {
    setBookingData: (state, action) => {
      const updatedState = { ...state, ...action.payload };
      // Save the updated state to session storage
      sessionStorage.setItem("bookingData", JSON.stringify(updatedState));
      return updatedState;
    },
  },
});

export const { setBookingData } = bookingSlice.actions;

export default bookingSlice.reducer;
