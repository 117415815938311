import guest1 from "../../assets/img/guest/guest1.png";
import guest2 from "../../assets/img/guest/guest2.png";
import guest3 from "../../assets/img/guest/guest3.png";
import guest4 from "../../assets/img/guest/guest4.png";
import guest5 from "../../assets/img/guest/guest5.png";
import guest6 from "../../assets/img/guest/guest6.png";
import Slider from "react-slick";
import ProfileCard from "./ProfileCard";

const guestReviews = [
  {
    name: "Amit Patel",
    review:
      "Comfort and Sustainability Combined. Staying at this eco hotel was a fantastic experience. The rooms were incredibly comfortable. It’s clear that this hotel is committed to reducing its carbon footprint, and that made my stay even more enjoyable.",
    image: guest1,
  },
  {
    name: "Meera Kapoor",
    review:
      "Perfect Balance of Luxury and Eco-Friendliness. This hotel exceeded my expectations. The luxurious amenities didn’t compromise its commitment to sustainability.",
    image: guest2,
  },
  {
    name: "Rajesh Kumar",
    review:
      "A Sustainable Stay Done Right. I was blown away by the level of eco-consciousness at this hotel. They’ve really thought of everything—from water conservation efforts to waste reduction programs.",
    image: guest3,
  },
  {
    name: "Sanjay Rao",
    review:
      "Sustainability Done Right. This hotel sets the bar high for eco-friendly hospitality. From the moment I arrived, I noticed the attention to detail in their sustainable practices. The energy-efficient appliances were great.",
    image: guest4,
  },
  {
    name: "Vikram",
    review:
      "Green Luxury at Its Best. I’ve stayed at many hotels, but this one truly stands out for its commitment to the environment. Highly recommend this place if you want luxury with a conscience.",
    image: guest5,
  },
  {
    name: "Aishwarya Desai",
    review:
      "Eco-Conscious and Cozy. Staying at this eco hotel was a refreshing change. The atmosphere was cozy and inviting, with an emphasis on sustainability that I really appreciated. It’s the perfect place for anyone looking to travel responsibly.",
    image: guest6,
  },
];

const GuestReviews = () => {
  const reviewSetting = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 1500,
    swipeToSlide: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
  };
  return (
    <div className="bg-white radius-20 p-4 pb-5">
      <p className="label fw-bold">Review</p>
      <Slider arrows={false} className="slider slide-show" {...reviewSetting}>
        {guestReviews?.map((d, i) => (
          <ProfileCard
            key={i}
            image={d?.image}
            name={d?.name}
            content={d?.review}
          />
        ))}
      </Slider>
    </div>
  );
};

export default GuestReviews;
