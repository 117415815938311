import comment1 from "../assets/img/blog/details-comment1.jpg";
import comment2 from "../assets/img/blog/details-comment2.jpg";
import TopBanner from "./Banner";
import NewsCover from "../assets/img/news/NewsCover.jpg";
import EcoHotel from "../assets/img/news/EcoHotel.webp";
import RecentNews1 from "../assets/img/news/recent-news-1.webp";
import RecentNews2 from "../assets/img/news/recent-news-2.jpg";
import RecentNew3 from "../assets/img/news/recent-news-3.webp";

function NewsDetails() {
  return (
    <>
      <TopBanner />
      <section className="blog-details-area pat-100 pab-100">
        <div className="container">
          <div className="shop-contents-wrapper">
            <div className="row gy-5">
              <div className="col-xl-8 col-lg-8">
                <div className="blog-details-wrapper">
                  <div className="single-blog-details">
                    <div className="single-blog-details-content pt-0">
                      <h2 className="single-blog-details-content-title fw-500">
                        {" "}
                        Matching supply with demand for eco-friendly hospitality{" "}
                      </h2>
                      <div className="single-blog-details-content-tags mt-3">
                        <span className="single-blog-details-content-tags-item">
                          {" "}
                          <span> Category </span>{" "}
                        </span>
                        <span className="single-blog-details-content-tags-item">
                          {" "}
                          28 Jun 2022{" "}
                        </span>
                      </div>
                      <div className="single-blog-details-thumb mt-4">
                        <img className="radius-5" src={NewsCover} alt="" />
                      </div>
                      <p className="single-blog-details-content-para mt-4">
                        By the final quarter of last year, clean across the
                        Globe, no less than 14,267 projects were underway for
                        the construction of new Hotel accommodation (a 4%
                        increase year on year: www. lodgingeconometrics.com):
                        India was third in the global league table (after the
                        United States and China). And, as I write this, the
                        pipeline continues to run hot, at a record high in fact,
                        which is a tangible expression of just how successful
                        worldwide hospitality has become since the dismal,
                        locked-in days of COVID restrictions. The figure I
                        mentioned also bears stark testament to both the
                        flexibility and overall responsiveness of the sector:
                        seemingly pivoting on a sixpence to increase resources,
                        just when so-called revenge tourism has assumed peak
                        traction: and, if you didn’t know already, that means
                        the increasing trend for international and domestic
                        travellers to break free of (hopefully) distant memories
                        of lockdown restrictions and seek out ever more exotic
                        destinations, both at home and abroad.
                      </p>
                      <p className="single-blog-details-content-para mt-4">
                        {" "}
                        If you’re thinking this all sounds a bit too much like a
                        marketing jingle, just consider the hard facts for a
                        moment: according to the latest UNWTO World Tourism
                        Barometer (www.unwto.org), more than 235 Million
                        tourists travelled internationally in the first quarter
                        of this year (double the equivalent period in 2022): so,
                        with due deference to the laws of supply and demand,
                        this is precisely the time to be making new hotel
                        accommodation available… there’s an attractive symmetry
                        in the way markets frequently function, and that’s
                        certainly the case when it comes to hospitality.{" "}
                      </p>

                      <div>
                        <h6 className="pat-30 fw-bold">
                          Sustainable and Eco Friendly
                        </h6>
                        <p>
                          Of course, not just any old accommodation will do…this
                          tidal wave of travellers isn’t only hungry for new and
                          more interesting destinations. Research consistently
                          shows that they also want to stay in hotels that are
                          both sustainable and eco-friendly: 82% of them, in
                          fact, according to global travel brand Virtuoso
                          (www.virtuoso.com). After all, nobody wants to wind up
                          at the airport to find a clapped-out Fiesta waiting
                          for them (well, almost nobody), and virtually no one
                          wants to stay at some concrete monolith of a hotel
                          whose only idea of sustainability is tossing towels in
                          a bath and replacing the soap every third day.
                          <br />
                          <br />
                          That’s why more and more of those new hotels are
                          currently being built to rigorous eco-friendly
                          standards, capable of enhancing the guest experience
                          as well as improving bottom-line efficiencies and
                          community engagement.
                          <br />
                          <br />A truly eco-friendly hotel is built from the
                          ground up with sustainability at its core: integrating
                          emerging technologies, streamlining infrastructure,
                          and removing all those troglodyte pinch points (such
                          as, yes, tossing towels in the bath), so as to fully
                          enhance the way in which guests can engage with their
                          immediate environment and the wider community.
                          <br />
                          <br />
                          The hotel they stay in will now be increasingly
                          constructed with greener materials and more often put
                          together using Modular Technologies, which not only
                          reduce environmental waste but also reduce delivery
                          timelines and enhance the bottom line (conventionally
                          by up to 40%, according to McKinsey:
                          www.mckinsey.com). Smart infrastructure can, and does,
                          also help reduce the hotel’s operating costs by
                          monitoring energy wastage levels, optimising water
                          usage, and highlighting the need for any preventative
                          maintenance…and all that’s good for the environment as
                          well…helping preserve our precious planet for future
                          generations.
                        </p>
                        <h6 className="pat-30 fw-bold">Eco Hotels Ever</h6>
                        <p>
                          since Eco Hotels was founded more than a decade ago,
                          sustainability and environmental consciousness have
                          been built into its DNA from the ground up: fostering
                          energy efficiency, advanced technologies, and
                          eco-friendly operations, as well as sourcing supplies
                          to support local communities: managing its operations
                          with a clarity of vision that brings a new dimension
                          to hospitality.
                        </p>
                      </div>

                      <div className="blog-details-advertise center-text mt-4">
                        <a href="javascript:void(0)">
                          {" "}
                          <img
                            className="radius-5"
                            src={EcoHotel}
                            alt=""
                          />{" "}
                        </a>

                        <p className="pat-10">
                          Red Ribbon Asset Management is the founder of Eco
                          Hotels, the world’s first carbon neutral mid-market
                          hotel brand, offering “green hospitality” as part of a
                          progressive roll out across India which intended to
                          take full advantage of current market opportunities on
                          the subcontinent.{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="details-tag-area color-two pat-25 pab-50">
                    <div className="row align-items-center">
                      <div className="col-lg-12 mt-4">
                        <div className="blog-details-share-content">
                          <h4 className="blog-details-share-content-title">
                            {" "}
                            Share:{" "}
                          </h4>
                          <ul className="blog-details-share-social list-style-none">
                            <li className="blog-details-share-social-list">
                              <a
                                className="blog-details-share-social-list-icon"
                                href="javascript:void(0)"
                              >
                                {" "}
                                <i className="lab la-facebook-f"></i>{" "}
                              </a>
                            </li>
                            <li className="blog-details-share-social-list">
                              <a
                                className="blog-details-share-social-list-icon"
                                href="javascript:void(0)"
                              >
                                {" "}
                                <i className="lab la-twitter"></i>{" "}
                              </a>
                            </li>
                            <li className="blog-details-share-social-list">
                              <a
                                className="blog-details-share-social-list-icon"
                                href="javascript:void(0)"
                              >
                                {" "}
                                <i className="lab la-pinterest-p"></i>{" "}
                              </a>
                            </li>
                            <li className="blog-details-share-social-list">
                              <a
                                className="blog-details-share-social-list-icon"
                                href="javascript:void(0)"
                              >
                                {" "}
                                <i className="lab la-youtube"></i>{" "}
                              </a>
                            </li>
                            <li className="blog-details-share-social-list">
                              <a
                                className="blog-details-share-social-list-icon"
                                href="javascript:void(0)"
                              >
                                {" "}
                                <i className="lab la-instagram"></i>{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="comment-area color-two pat-50 pab-50">
                    <h3 className="details-section-title"> Comments(03) </h3>
                    <div className="row">
                      <div className="col-lg-12 mt-2">
                        <div className="comment-show-contents">
                          <ul
                            className="comment-list list-style-none wow fadeInLeft"
                            data-wow-delay=".1s"
                          >
                            <li>
                              <div className="blog-details-flex-content">
                                <div className="blog-details-thumb radius-10">
                                  <img src={comment1} alt="" />
                                </div>
                                <div className="blog-details-content">
                                  <div className="blog-details-content-flex">
                                    <div className="blog-details-content-item">
                                      <h5 className="blog-details-content-title">
                                        {" "}
                                        <a href="javascript:void(0)">
                                          {" "}
                                          Riyad Hossain{" "}
                                        </a>{" "}
                                      </h5>
                                      <span className="blog-details-content-date">
                                        {" "}
                                        Jun 22, 2022{" "}
                                      </span>
                                    </div>
                                    <a
                                      href="javascript:void(0)"
                                      className="btn-replay"
                                    >
                                      {" "}
                                      <i className="las la-reply-all"></i> Reply{" "}
                                    </a>
                                  </div>
                                  <p className="blog-details-content-para">
                                    {" "}
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Sed a egestas leo. Aliquam
                                    ut ante lobortis tellus cursus pellentesque.
                                    Praesent feugiat tellus quis aliquet{" "}
                                  </p>
                                </div>
                              </div>
                              <ul
                                className="comment-list list-style-none wow fadeInLeft"
                                data-wow-delay=".2s"
                              >
                                <li>
                                  <div className="blog-details-flex-content">
                                    <div className="blog-details-thumb radius-10">
                                      <img src={comment2} alt="" />
                                    </div>
                                    <div className="blog-details-content">
                                      <div className="blog-details-content-flex">
                                        <div className="blog-details-content-item">
                                          <h5 className="blog-details-content-title">
                                            {" "}
                                            <a href="javascript:void(0)">
                                              {" "}
                                              Rajia Akter{" "}
                                            </a>{" "}
                                          </h5>
                                          <span className="blog-details-content-date">
                                            {" "}
                                            Jun 23, 2022{" "}
                                          </span>
                                        </div>
                                        <a
                                          href="javascript:void(0)"
                                          className="btn-replay"
                                        >
                                          {" "}
                                          <i className="las la-reply-all"></i>{" "}
                                          Reply{" "}
                                        </a>
                                      </div>
                                      <p className="blog-details-content-para">
                                        {" "}
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Sed a egestas leo.
                                        Aliquam ut ante lobortis tellus cursus
                                        pellentesque. Praesent feugiat tellus
                                        quis aliquet{" "}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul
                            className="comment-list list-style-none wow fadeInLeft"
                            data-wow-delay=".3s"
                          >
                            <li>
                              <div className="blog-details-flex-content">
                                <div className="blog-details-thumb radius-10">
                                  <img src={comment1} alt="" />
                                </div>
                                <div className="blog-details-content">
                                  <div className="blog-details-content-flex">
                                    <div className="blog-details-content-item">
                                      <h5 className="blog-details-content-title">
                                        {" "}
                                        <a href="javascript:void(0)">
                                          {" "}
                                          Bryn Colon{" "}
                                        </a>{" "}
                                      </h5>
                                      <span className="blog-details-content-date">
                                        {" "}
                                        Jun 24, 2022{" "}
                                      </span>
                                    </div>
                                    <a
                                      href="javascript:void(0)"
                                      className="btn-replay"
                                    >
                                      {" "}
                                      <i className="las la-reply-all"></i> Reply{" "}
                                    </a>
                                  </div>
                                  <p className="blog-details-content-para">
                                    {" "}
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Sed a egestas leo. Aliquam
                                    ut ante lobortis tellus cursus pellentesque.
                                    Praesent feugiat tellus quis aliquet{" "}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="btn-wrapper mt-4">
                            <a
                              href="javascript:void(0)"
                              className="btn-see-more"
                            >
                              {" "}
                              Show More{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="comment-area color-two pat-50">
                    <h3 className="details-section-title">
                      {" "}
                      Post Your Comment{" "}
                    </h3>
                    <div className="row">
                      <div className="col-lg-12 pat-20">
                        <div className="details-comment-content">
                          <div className="comments-flex-item">
                            <div className="single-commetns">
                              <label className="comment-label">
                                {" "}
                                Your Name{" "}
                              </label>
                              <input
                                type="text"
                                className="form--control radius-5 dark-border"
                                name="name"
                                placeholder="Type Name"
                              />
                            </div>
                            <div className="single-commetns">
                              <label className="comment-label">
                                {" "}
                                Email Address{" "}
                              </label>
                              <input
                                type="text"
                                className="form--control radius-5"
                                name="email"
                                placeholder="Type Email"
                              />
                            </div>
                          </div>
                          <div className="single-commetns">
                            <label className="comment-label"> Comment </label>
                            <textarea
                              name="message"
                              className="form--control radius-5 form--message"
                              placeholder="Post Comments"
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="submit-btn radius-5 mt-4"
                          >
                            {" "}
                            Post Comment{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <div className="blog-details-side">
                  <div className="blog-details-side-item radius-10">
                    <div className="blog-details-side-title open">
                      <h5 className="title"> Recent Post </h5>
                      <div className="blog-details-side-inner">
                        <ul className="recent-list list-style-none">
                          <li className="recent-list-item">
                            <div className="recent-list-thumb">
                              <a href="#0">
                                {" "}
                                <img
                                  className="img-fluid radius-5"
                                  style={{ height: "60px", width: "90px" }}
                                  src={RecentNews1}
                                  alt=""
                                />{" "}
                              </a>
                            </div>
                            <div className="recent-list-contents">
                              <h6 className="recent-list-title fs-16">
                                {" "}
                                <a href="#0">
                                  {" "}
                                  Hotel Market Recovery: A Global Perspective by
                                  CBRE{" "}
                                </a>{" "}
                              </h6>
                              <span className="recent-list-dates light-color fs-14 mt-2">
                                {" "}
                                21 Jun 2022{" "}
                              </span>
                            </div>
                          </li>
                          <li className="recent-list-item">
                            <div className="recent-list-thumb">
                              <a href="#0">
                                {" "}
                                <img
                                  className="img-fluid radius-5"
                                  style={{ height: "60px", width: "90px" }}
                                  src={RecentNews2}
                                  alt=""
                                />{" "}
                              </a>
                            </div>
                            <div className="recent-list-contents">
                              <h6 className="recent-list-title fs-16">
                                {" "}
                                <a href="#0">
                                  {" "}
                                  Oxford Economics - Future Of Construction 2030{" "}
                                </a>{" "}
                              </h6>
                              <span className="recent-list-dates light-color fs-14 mt-2">
                                {" "}
                                22 Jun 2022{" "}
                              </span>
                            </div>
                          </li>
                          <li className="recent-list-item">
                            <div className="recent-list-thumb">
                              <a href="#0">
                                {" "}
                                <img
                                  className="img-fluid radius-5"
                                  style={{ height: "60px", width: "90px" }}
                                  src={RecentNew3}
                                  alt=""
                                />{" "}
                              </a>
                            </div>
                            <div className="recent-list-contents">
                              <h6 className="recent-list-title fs-16">
                                {" "}
                                <a href="#0">
                                  {" "}
                                  McKinsey & Company: Private markets rally to
                                  new heights{" "}
                                </a>{" "}
                              </h6>
                              <span className="recent-list-dates light-color fs-14 mt-2">
                                {" "}
                                24 Jun 2022{" "}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsDetails;
