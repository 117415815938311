import summerOffer from "../../assets/img/events/event-menu-2.png";
import React, { useState, useEffect, useRef } from "react";
import HotelTab from "../Hotel/HotelTab";
import MapImage from "../../assets/img/hotel/map.png";
import SearchBox from "../Hotel/SearchBox";

import GuestReviews from "../Hotel/GuestReview";
import Carousel from "./Carousel";
import HotelAbout from "./HotelAbout";
import HotelNearby from "./HotelNearby";
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function HotelPageBuilder(props) {
  const {
    hotelName,
    logo,
    address,
    phone,
    email,
    amenities,
    aboutHotel,
    sections,
    coverImages,
    factSheet,
    nearby,
    nearbyLink,
  } = props;

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const [value, setValue] = React.useState(0);
  const tabsRef = useRef(null);

  const handleScroll = (direction) => {
    if (tabsRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      tabsRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    scrollToDiv(newValue);
  };

  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const divRefs = useRef([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  // Function to scroll to a specific div
  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  const sec = sections.slice(0, sections.length - 1);

  return (
    <>
      {/* <TopBanner /> */}
      <section className="hotel-details-area section-bg-2 pat-90 pat-sm-120 pab-50">
        <div className="container">
          <SearchBox />

          <div className="row g-4 pat-20">
            <div className="col-xl-9 col-lg-9">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  {/* Carousel --- */}
                  <Carousel images={coverImages} />

                  <div className="hotel-view-contents">
                    {/* Hotel About Section */}
                    <HotelAbout
                      logo={logo}
                      name={hotelName}
                      address={address}
                      phone={phone}
                      email={email}
                      amenities={amenities}
                      about={aboutHotel}
                      factSheet={factSheet}
                    />

                    <div className="hotel-view-contents-middle">
                      {/* Top Navigation */}
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={isMobile ? "start" : "center"}
                        my={4}
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          variant={isMobileOrTablet ? "scrollable" : "standard"}
                          scrollButtons="auto"
                          ref={tabsRef}
                          TabIndicatorProps={{ hidden: true }}
                          sx={{
                            "& .MuiTabs-flexContainer": {
                              gap: { sm: 5 },
                            },
                            "& .MuiTabs-scrollButtons": {
                              display: "flex",
                            },
                            "@media (max-width: 600px)": {
                              "& .MuiTabs-scrollButtons.Mui-disabled": {
                                display: "flex",
                              },
                            },
                          }}
                        >
                          {sections?.map((item, index) => (
                            <Tab
                              key={item.id}
                              label={item.label}
                              className="verdana"
                              sx={{
                                borderRadius: "50px",
                                backgroundColor: "transparent",
                                color: "inherit",
                                padding: "8px 36px",
                                minWidth: "auto",
                                transition: "all 0.3s ease",
                                "&.Mui-selected": {
                                  border: "1px solid #8b4513",
                                  color: "black",
                                },
                                "&:hover": {
                                  border: "1px solid #8b4513",
                                  color: "black",
                                },
                              }}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      {sec.map((section, index) => (
                        <div
                          key={section.id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          <HotelTab
                            title={section.label}
                            image={section.image}
                            type={section.type}
                            tab={true}
                            data={section.data}
                            style={section.style}
                          />
                        </div>
                      ))}

                      {/* Hotel Nearby */}
                      <HotelNearby
                        divRefs={divRefs}
                        nearby={nearby}
                        link={nearbyLink}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="sticky-top">
                <p className="label">View in map</p>
                <img src={MapImage} alt="map" />

                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10 my-4">
                  <p className="label mb-2 fw-bold">
                    Celebrate Sustainably, Host Elegantly – Book Your
                    Eco-Friendly Event at The Eco Satva
                  </p>
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={summerOffer} alt="" />
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <GuestReviews />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HotelPageBuilder;
