import React, { useEffect, useState } from "react";
import ArrowBack from "../../assets/img/hotel/arrow_back_ios.svg";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../lib/axiosConfig";
import { calculateDaysBetweenDates } from "../helper/BetweenDates";
import Subscription from "../helper/Subscription";
import { TramOutlined } from "@mui/icons-material";
import {
  DialogActions,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
  ListItem,
  Divider,
  List,
  Button,
  useTheme,
  useMediaQuery,
  ListItemText,
} from "@mui/material";
import { toSentenceCase } from "../helper/formatString";
import logo2 from "../../assets/img/logo-final.png";
import { useSearchParams } from "react-router-dom";

const CheckoutForm = () => {
  // Handle Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam] = useSearchParams();
  const [agree, setAgree] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [guestDetails, setGuestDetails] = useState({
    prefix: "Mr.",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    country: "",
    state: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleValidateForm = () => {
    let validationErrors = {};

    if (!guestDetails.firstName.trim()) {
      validationErrors.firstName = "First name is required";
    }

    if (!guestDetails.lastName.trim()) {
      validationErrors.lastName = "Last name is required";
    }

    if (!guestDetails.mobile.trim()) {
      validationErrors.mobile = "Mobile number is required";
    }

    if (!guestDetails.email.trim()) {
      validationErrors.email = "Email is required";
    }
    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleOnChange = (key, value) => {
    setGuestDetails((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const searchData = {
    location: {
      propertyId: searchParam.get("property"),
      companyId: searchParam.get("company"),
    },
    checkIn: searchParam.get("checkin"),
    checkOut: searchParam.get("checkout"),
    person: searchParam.get("person"),
    children: searchParam.get("children"),
    room: searchParam.get("room"),
  };

  const [serviceId, setServiceId] = useState();

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // -------------- HANDLE USE-SELECTOR ---------------- //
  const bookingData = useSelector((state) => state.booking);
  // const searchData = useSelector((state) => state.search);

  let price = 0;
  const roomPrice = parseFloat(bookingData?.totalPrice);
  const nights = bookingData?.priceMatrix?.length;
  const noOfRooms = parseInt(searchData?.room);

  console.log(roomPrice, "room-price");

  if (bookingData?.addOns) {
    const addOnPrice =
      parseFloat(bookingData?.addOns?.price) *
      parseFloat(searchData?.person) *
      nights;

    console.log(addOnPrice, bookingData?.addOns?.price, "add-on-price");
    price = (addOnPrice + roomPrice) * noOfRooms;
  } else {
    price = roomPrice * noOfRooms;
  }

  function extractTAX(taxes) {
    const TAX = Object.entries(taxes).map(([key, value]) => {
      return { name: key, price: (value / 100) * price };
    });
    return TAX;
  }

  const Taxes = extractTAX(bookingData?.taxes)?.map((item) => {
    return item;
  });

  const priceWithTAX = price + Taxes.reduce((acc, item) => acc + item.price, 0);

  let addOnsId = [];

  if (bookingData?.addOns) {
    addOnsId = [bookingData?.addOns?._id];
  }

  const serviceData = [];
  let noOfAdult = parseInt(searchData?.person);
  for (let i = 0; i < noOfRooms; i++) {
    const service = {
      roomTypeId: bookingData?._id,
      addOnIds: addOnsId,
      noOfGuest: {
        adult: parseInt(searchData?.person),
        children: parseInt(searchData?.children),
      },
    };

    // if noOfAdult is three then divide and distribute the adults in each room
    if (noOfAdult > 2) {
      service.noOfGuest.adult = 2;
      noOfAdult = noOfAdult - 2;
    } else {
      service.noOfGuest.adult = noOfAdult;
    }

    serviceData.push(service);
  }

  console.log(serviceData);

  const submitDetails = {
    noOfAdults: parseInt(searchData?.person),
    noOfChildren: parseInt(searchData?.children),
    bookerDetails: {
      email: guestDetails.email,
      firstName: guestDetails.firstName,
      lastName: guestDetails.lastName,
      phone: guestDetails.mobile,
      countryCode: "+91",
      isBookerGuest: true,
    },
    mainGuestsDetails: {
      email: guestDetails.email,
      firstName: guestDetails.firstName,
      lastName: guestDetails.lastName,
      phone: guestDetails.mobile,
      address: guestDetails.address1,
      dob: "",
      age: "",
      countryCode: "+91",
      city: guestDetails.city,
      country: guestDetails.country,
      state: guestDetails.state,
      zipCode: guestDetails.zip,
      TAXNo: "",
      nationality: guestDetails.country,
    },
    // attachmentIds: ["6694d9cd9d320c0019f2e8af"],
    startDateTime: searchData.checkIn,
    endDateTime: searchData.checkOut,
    source: "Website",
    serviceRequests: serviceData,
  };

  const companyId = searchData?.location?.companyId;
  const propertyId = searchData?.location?.propertyId;

  const handlePaymentGayeway = async (serviceId) => {
    const paymentDetails = {
      companyId: companyId,
      propertyId: propertyId,
      revenueCenterId: bookingData?.revenueCenterId,
      serviceRequestId: serviceId,
      amountPaid: priceWithTAX,
      currency: "INR",
      email: guestDetails.email,
      countryCode: "+91",
      phone: guestDetails.mobile,
      returnUrl: `https://build.ecohotels.in/payment-confirmation`,
    };

    try {
      const res = await axios({
        method: "POST",
        url: `/public/payment/payment-link`,
        data: paymentDetails,
      });

      // setTransactionId();
      const txId = res?.data?.data?.order_id;
      console.log(txId);
      const sendPaymentData = {
        companyId: companyId,
        propertyId: propertyId,
        serviceId: serviceId,
        transactionId: txId,
        amount: priceWithTAX.toFixed(2),
      };

      localStorage.setItem("bookingBlockData", JSON.stringify(submitDetails));
      localStorage.setItem("sendPaymentData", JSON.stringify(sendPaymentData));
      window.location.replace(res?.data?.data?.payment_links?.web);
    } catch (error) {
      setIsError(true);
      throw new Error(error);
    }
  };

  const handleCloseValidation = () => {
    setIsValidate(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubmitForm = async (type) => {
    setIsLoading(TramOutlined);

    if (!handleValidateForm()) {
      setIsValidate(true);
      setIsLoading(false);
      return;
    }

    try {
      const res = await axios({
        method: "POST",
        url: `/public/service-requests/room-type/${searchData?.location?.companyId}/${searchData?.location?.propertyId}/block`,
        data: submitDetails,
        headers: {
          applicationId: "60879d4572e7c85fe46ac1d6",
        },
      });

      if (res) {
        const serviceId = res?.data?.data[0]?.parentServiceRequestId;
        setServiceId(serviceId);
        setIsLoading(false);
        handleOpenPopup();
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setIsError(true);
      setIsLoading(false);
    }
  };

  const handleConfirmBooking = async () => {
    setIsLoading(TramOutlined);
    if (paymentType === "pay-now") {
      handlePaymentGayeway(serviceId);
    } else {
      try {
        const res = await axios({
          method: "PATCH",
          url: `/public/service-requests/room-type/${searchData?.location?.companyId}/${searchData?.location?.propertyId}/${serviceId}/confirm`,

          data: { paymentStatus: "Unpaid" },
          headers: {
            applicationId: "60879d4572e7c85fe46ac1d6",
          },
        });

        if (res) {
          setIsLoading(false);
          setShowConfirm(true);
          handleClosePopup();
          setTimeout(() => {
            window.location.replace("/");
          }, 5000);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        alert("Something went wrong");
      }
    }
  };

  return (
    <div className="bg-gray pab-100">
      <div className="container pat-120 pe-auto">
        <div
          className="d-flex gap-3"
          onClick={() => {
            window.history.back();
          }}
        >
          <div>
            <img src={ArrowBack} alt="Back" />
          </div>
          <h5 className="card-title fw-bold">Checkout</h5>
        </div>
        <div className="row pat-20">
          <div className="col-xl-8 ">
            <div className="card p-4 radius-20 details-contents">
              <div className="card-body">
                <form>
                  <h5 className="fw-600">Guest Details</h5>
                  <div className="row my-3 gap-2 gap-md-0">
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPrefix" className="label">
                        Prefix *
                      </label>
                      <select
                        id="inputPrefix"
                        className="form-control custom-input-style"
                        onChange={(e) =>
                          handleOnChange("prefix", e.target.value)
                        }
                      >
                        <option value={"Mr."}>Mr.</option>
                        <option value={"Mrs."}>Mrs.</option>
                        <option value={"Miss"}>Miss</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label className="label" htmlFor="inputFirstName">
                        First Name *
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputFirstName"
                        placeholder="First Name"
                        onChange={(e) =>
                          handleOnChange("firstName", e.target.value)
                        }
                      />
                      {errors.firstName && (
                        <p style={{ color: "red" }}>{errors.firstName}</p>
                      )}
                    </div>
                    <div className="form-group col-md-5">
                      <label className="label" htmlFor="inputLastName">
                        Last Name *
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputLastName"
                        placeholder="Last Name"
                        onChange={(e) =>
                          handleOnChange("lastName", e.target.value)
                        }
                      />
                      {errors.lastName && (
                        <p style={{ color: "red" }}>{errors.lastName}</p>
                      )}
                    </div>
                  </div>

                  <div className="row mb-3 gap-2 gap-md-0">
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="inputMobile">
                        Mobile *
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputMobile"
                        placeholder="Mobile"
                        onChange={(e) =>
                          handleOnChange("mobile", e.target.value)
                        }
                      />
                      {errors.mobile && (
                        <p style={{ color: "red" }}>{errors.mobile}</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="inputEmail">
                        Email Address *
                      </label>
                      <input
                        type="email"
                        className="form-control custom-input-style"
                        id="inputEmail"
                        placeholder="Email Address"
                        onChange={(e) =>
                          handleOnChange("email", e.target.value)
                        }
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email}</p>
                      )}
                    </div>
                  </div>

                  <h5 className="mt-5 fw-600">Address</h5>

                  <div className="row my-3 gap-2 gap-md-0">
                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="inputAddress1">
                        Address Line 1
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputAddress1"
                        placeholder="Eg. 1234 Main St"
                        onChange={(e) =>
                          handleOnChange("address1", e.target.value)
                        }
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="label" htmlFor="inputAddress2">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputAddress2"
                        placeholder="Eg. Apartment, suite, unit, building, floor, etc."
                        onChange={(e) =>
                          handleOnChange("address2", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="row mb-3 gap-2 gap-md-0">
                    <div className="form-group col-md-4">
                      <label className="label" htmlFor="inputState">
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        placeholder="Eg. Delhi"
                        id="inputState"
                        onChange={(e) =>
                          handleOnChange("state", e.target.value)
                        }
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label className="label" htmlFor="inputCity">
                        City
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputCity"
                        placeholder="Eg. New York"
                        onChange={(e) => handleOnChange("city", e.target.value)}
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label className="label" htmlFor="inputZip">
                        Zip / Postal Code
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputZip"
                        onChange={(e) => handleOnChange("zip", e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="form-group col-md-12">
                      <label className="label" htmlFor="inputCountry">
                        Country *
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-style"
                        id="inputCountry"
                        placeholder="Eg. India"
                        onChange={(e) =>
                          handleOnChange("country", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <h5 className="mt-5 fw-600">Identity Proof</h5>
                  <div className="row my-3">
                    <div className="form-group col-md-4">
                      <label className="label" htmlFor="inputIdentityProof">
                        Identity Proof*
                      </label>
                      <input
                        type="file"
                        accept="png/*, jpg/*, jpeg/*, pdf/*, docx/*"
                        className="form-control custom-input-style"
                        id="inputIdentityProof"
                      />
                    </div>
                  </div>

                  <div className="mt-5">
                    <h5 className="mb-4 fw-bold">Policies</h5>

                    <div className="mb-4">
                      <h6 className="label fw-bold">Check-In Policy:</h6>
                      <ul>
                        <li>
                          <strong>Check-In Time:</strong> Our standard check-in
                          time is from 1:00 PM onwards. Early check-ins may be
                          accommodated based on room availability and may incur
                          an additional fee. Please contact us in advance to
                          make arrangements.
                        </li>
                        <li>
                          <strong>Eco-Friendly Practices:</strong> To minimize
                          our environmental impact, we encourage guests to limit
                          their use of single-use plastics and participate in
                          our recycling programs. Please consider bringing your
                          own reusable water bottle.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h6 className="label fw-bold">Check-Out Policy:</h6>
                      <ul>
                        <li>
                          <strong>Check-Out Time:</strong> Our standard
                          check-out time is by 11:00 AM. Late check-outs may be
                          available upon request and may incur an additional
                          fee. Please inform the front desk if you wish to check
                          out later.
                        </li>
                        <li>
                          <strong>Sustainability Reminder:</strong> We
                          appreciate your efforts to reduce your carbon
                          footprint during your stay. Kindly ensure that all
                          lights and electronic devices are turned off before
                          leaving the room.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h6 className="label fw-bold">Cancellation Policy:</h6>
                      <ul>
                        <li>
                          <strong>Cancellation Notice:</strong> Cancellations
                          must be made at least 48 hours prior to the scheduled
                          check-in date to avoid any cancellation fees.
                          Cancellations made within 48 hours of the check-in
                          date will incur a charge equivalent to one night’s
                          stay.
                        </li>
                        <li>
                          <strong>Refunds:</strong> Refunds for cancellations or
                          changes will be processed to the original payment
                          method and may take several business days to reflect
                          in your account.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-3">
                      <p className="text-muted">
                        <strong>Note:</strong> As an eco-conscious hotel, we
                        strive to maintain our environmental policies while
                        offering flexibility to our guests. We appreciate your
                        understanding and cooperation in helping us promote
                        sustainable tourism.
                      </p>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="privacyPolicy"
                        onClick={() => setAgree(true)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="privacyPolicy"
                      >
                        I agree with the Privacy Policy
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-item-center gap-3">
                    <div className="btn-wrapper ">
                      <button
                        onClick={() => {
                          window.history.back();
                        }}
                        className="cmn-btn btn-bg-1 btn-small"
                      >
                        Cancel Booking
                      </button>
                    </div>
                    {/* <div className="btn-wrapper ">
                      <button
                        className={`cmn-btn  btn-small ${
                          !agree ? "btn-bg-secondary" : "btn-bg-2"
                        }`}
                        type="button"
                        onClick={() => {
                          handleSubmitForm();
                          setPaymentType("pay-now");
                        }}
                        disabled={!agree}
                      >
                        Pay Now
                      </button>
                    </div> */}
                    <div className="btn-wrapper ">
                      <button
                        className={`cmn-btn  btn-small ${
                          !agree ? "btn-bg-secondary" : "btn-bg-2"
                        }`}
                        type="button"
                        onClick={() => {
                          handleSubmitForm();
                          setPaymentType("pay-later");
                        }}
                        disabled={!agree || isError}
                      >
                        Pay at Hotel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-xl-4 mt-4 mt-xl-0">
            <Box className="widget bg-white radius-10 details-contents">
              <Box className="checkout-sidebar">
                <Typography
                  variant="h6"
                  gutterBottom
                  pt={2}
                  px={"20px"}
                  fontWeight={700}
                >
                  Booking Details
                </Typography>
                <Divider />
                <Box p={"5px"}>
                  <List
                    sx={{
                      "& .MuiListItem-root": {
                        paddingTop: "6px",
                        paddingBottom: "6px",
                      },
                      "& .MuiDivider-root": {
                        margin: "0px",
                      },
                    }}
                  >
                    {/* Room Type */}
                    <ListItem>
                      <Typography variant="body1" className="label">
                        Room Type
                      </Typography>
                      <Typography
                        variant="body1"
                        className="text-gold"
                        sx={{ marginLeft: "auto" }}
                      >
                        {toSentenceCase(bookingData?.name)}
                      </Typography>
                    </ListItem>

                    {/* Checking In */}
                    <ListItem>
                      <Typography variant="body1" className="label">
                        Checking In
                      </Typography>
                      <Typography
                        variant="body1"
                        className="text-gold"
                        sx={{ marginLeft: "auto" }}
                      >
                        {searchData?.checkIn}
                      </Typography>
                    </ListItem>

                    {/* Checking Out */}
                    <ListItem>
                      <Typography variant="body1" className="label">
                        Checking Out
                      </Typography>
                      <Typography
                        variant="body1"
                        className="text-gold"
                        sx={{ marginLeft: "auto" }}
                      >
                        {searchData?.checkOut}
                      </Typography>
                    </ListItem>

                    {/* Number of Rooms */}
                    <ListItem>
                      <Typography variant="body1" className="label">
                        Number of Rooms
                      </Typography>
                      <Typography
                        variant="body1"
                        className="text-gold"
                        sx={{ marginLeft: "auto" }}
                      >
                        {parseInt(searchData?.room) || 0}
                      </Typography>
                    </ListItem>

                    {/* Number of Person */}
                    <ListItem>
                      <Typography variant="body1" className="label">
                        Number of Guest
                      </Typography>
                      <Typography
                        variant="body1"
                        className="text-gold"
                        sx={{ marginLeft: "auto" }}
                      >
                        {searchData?.person} Guest
                      </Typography>
                    </ListItem>

                    {/* Number of Children */}
                    <ListItem>
                      <Typography variant="body1" className="label">
                        Number of Children
                      </Typography>
                      <Typography
                        variant="body1"
                        className="text-gold"
                        sx={{ marginLeft: "auto" }}
                      >
                        {searchData?.children} Children
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Box>
            <div class="checkout-widget checkout-widget-padding widget bg-white radius-20 mt-4 details-contents">
              <div className="checkout-sidebar ">
                <h4 className="checkout-sidebar-title mb-3">Invoice</h4>
                <hr />

                {/* Room Details */}
                <div className="checkout-sidebar-contents mb-3">
                  <h6 className="fw-bold label">
                    {toSentenceCase(bookingData?.name)}
                  </h6>

                  <li className="d-flex justify-content-between mt-1">
                    <p className="text-gold">
                      {bookingData?.addOns?.name
                        ? "(" + bookingData?.addOns?.name + ")"
                        : null}
                    </p>
                  </li>
                  <ul className="checkout-flex-list list-style-none">
                    <li className="d-flex justify-content-between">
                      <span className="label">
                        {nights > 1 ? `${nights} Nights` : `${nights} Night`}
                      </span>
                      <span>
                        <span className="fw-bold label">
                          ₹ {price.toFixed(2)}
                        </span>
                      </span>
                    </li>
                    <li className="d-flex justify-content-between mt-3">
                      <div className="label text-xs w-100">
                        {Taxes.map((item) => (
                          <div className="d-flex justify-content-between">
                            <li
                              style={{
                                listStyle: "none",
                              }}
                              key={item.name}
                            >
                              Tax ({item.name})
                            </li>
                            <span className="fw-bold label">
                              ₹ {item.price.toFixed(2)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>
                </div>

                {/* Grand Total */}
                <div className="checkout-sidebar-contents py-3 border-top mt-4">
                  <ul className="checkout-flex-list list-style-none">
                    <li className="d-flex justify-content-between">
                      <span className="fw-bold fs-18 label">Total</span>
                      <span className="fw-bold fs-20 label">
                        ₹ {priceWithTAX.toFixed(2)}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between">
                      <span className="fs-15 text-gold">(Including Tax)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}

      {showConfirm && (
        <Subscription
          title="Hotel Booking"
          onClose={() => setShowConfirm(false)}
        >
          <div style={{ textAlign: "center" }}>
            {/* <CheckCircleOutline style={{ fontSize: "100px", color: "green" }} /> */}
            <h5 className="label" style={{ fontSize: "24px", margin: "40px" }}>
              SUCCESSFULLY BOOKED
            </h5>
            <p className="text-gold">
              Thank you for choosing Eco Hotels for your upcoming stay! We’re
              excited to welcome you and ensure your visit is a memorable one.
              If there’s anything we can do to make your experience more
              enjoyable, please don't hesitate to let us know.
            </p>
          </div>
        </Subscription>
      )}

      <Dialog
        open={isPopupOpen}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle>
          <img
            src={logo2}
            alt="Eco Hotels Logo"
            style={{ float: "left" }}
            width={180}
          />
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              alignItems: isMobile ? "start" : "center",
            }}
          >
            Booking Details
          </Typography>

          <Typography variant="body1" gutterBottom>
            Dear{" "}
            {guestDetails.prefix +
              " " +
              toSentenceCase(guestDetails.firstName) +
              " " +
              toSentenceCase(guestDetails.lastName) || "XX"}
            ,
          </Typography>

          <Typography variant="body1" gutterBottom>
            Thanks for making a tentative reservation with us. We look forward
            to welcoming you at Eco Hotels.
          </Typography>

          {/* Your Schedule Section */}
          <Typography variant="h6" gutterBottom style={{ color: "#8a4513" }}>
            Your Stay Details
          </Typography>

          <Grid container spacing={2} mb={2}>
            {/* Left Column */}
            {isMobile ? (
              <List>
                <ListItem>
                  <ListItemText
                    primary="Name of the guest(s)"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {guestDetails.prefix +
                          " " +
                          toSentenceCase(guestDetails.firstName) +
                          " " +
                          toSentenceCase(guestDetails.lastName) || "XX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Check-in date"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {searchData?.checkIn || "XXXXXXXXXX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="No of nights"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {nights || "XXXXXXXXXX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Email ID"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {guestDetails.email || "XXXXXXXX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Adult(s)"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {searchData.person || "0"}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Number of room(s)"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {searchData?.room || "XX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Check-out date"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {searchData?.checkOut || "XXXXXXXXXX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Room Type"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {bookingData?.name || "XXXXXXXXXX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Mobile number"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {guestDetails?.mobile || "XXXXXXXXXX"}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="Children"
                    secondary={
                      <Typography variant="body2" className="text-gold">
                        {searchData?.children || "0"}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            ) : (
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="text-nowrap label">
                      Name of the guest(s)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {guestDetails.prefix +
                        " " +
                        toSentenceCase(guestDetails.firstName) +
                        " " +
                        toSentenceCase(guestDetails.lastName) || "XX"}
                    </Typography>
                  </Grid>

                  {/* Other rows */}
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Check-in date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {searchData?.checkIn || "XXXXXXXXXX"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      No of nights
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {nights || "XXXXXXXXXX"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Email ID
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {guestDetails.email || "XXXXXXXX"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Adult(s)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {searchData.person || "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* Right Column */}
            {!isMobile && (
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Number of room(s)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {searchData?.room || "XX"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Check-out date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {searchData?.checkOut || "XXXXXXXXXX"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Room Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {bookingData.name || "XXXXXXXXXX"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Mobile number
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {guestDetails.mobile || "XXXXXXXXXX"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" className="label">
                      Children
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className="text-gold">
                      {searchData.children || "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <div className="checkout-sidebar-contents mb-3 border-top">
            <h5 className="fw-600 mt-2 mb-2">Room Details</h5>
            <h6 className="fw-bold label">
              {toSentenceCase(bookingData?.name)}{" "}
              <span className="text-gold fw-normal">
                {bookingData?.addOns?.name
                  ? "(" + bookingData?.addOns?.name + ")"
                  : null}
              </span>
            </h6>

            <ul className="checkout-flex-list list-style-none">
              <li className="d-flex justify-content-between">
                <span className="label">
                  {nights > 1 ? `${nights} Nights` : `${nights} Night`}
                </span>
                <span>
                  <span className="fw-bold label">₹ {price.toFixed(2)}</span>
                </span>
              </li>
              <li className="w-100">
                {Taxes.map((item) => (
                  <div className="d-flex justify-content-between w-100">
                    <li
                      style={{
                        listStyle: "none",
                      }}
                      key={item.name}
                    >
                      Tax ({item.name})
                    </li>
                    <span className="fw-bold label">
                      ₹ {item.price.toFixed(2)}
                    </span>
                  </div>
                ))}
              </li>
            </ul>
          </div>

          <div className="checkout-sidebar-contents py-3 border-top mt-4">
            <ul className="checkout-flex-list list-style-none">
              <li className="d-flex justify-content-between">
                <span className="fw-bold fs-18 label">
                  Total{" "}
                  <span className="fw-normal fs-13 text-gold ml-1">
                    {" "}
                    (Including Tax)
                  </span>
                </span>
                <span className="fw-bold fs-20 label">
                  ₹ {priceWithTAX.toFixed(2)}
                </span>
              </li>
              <li className="d-flex justify-content-between"></li>
            </ul>
          </div>

          <Typography
            variant="body1"
            className="label"
            fontWeight={700}
            gutterBottom
          >
            Please note, that the suite/s and the rate will be confirmed subject
            to availability, at the time of receipt of the credit card
            information.
          </Typography>

          <Box
            sx={{
              padding: { md: 2 },
            }}
          >
            {/* Chauffeur on Arrival */}
            <Typography variant="h6" className="label">
              CHAUFFEUR ON ARRIVAL
            </Typography>
            <Typography variant="body2" gutterBottom>
              Should you ask for a chargeable (unless specified otherwise)
              airport pickup on arrival, the chauffeur and car details will be
              sent to you via SMS, one hour before the expected time of arrival
              of the flight. Please note: Delivery of the SMS is subject to the
              telecom service provider's network.
            </Typography>

            {/* Identity Proof */}
            <Typography variant="h6" className="label">
              IDENTITY PROOF
            </Typography>
            <Typography variant="body2" gutterBottom>
              In keeping with Government regulations, we request each guest
              (regardless of occupancy) to carry a photo identity to present on
              check-in. Foreign nationals are required to present their valid
              passport and visa. Indian nationals can present any
              government-issued photo identity and address proof card e.g.
              Driving license, Passport, Aadhar card, or Voter’s ID card, PAN
              Card will not be accepted. Please do keep handy proof of corporate
              affiliations, if you have made a corporate booking.
            </Typography>

            {/* No Smoking */}
            <Typography variant="h6" className="label">
              NO SMOKING
            </Typography>
            <Typography variant="body2" gutterBottom>
              The Government of India has introduced a ‘no smoking’ rule for
              hotels, restaurants, and all public places. This means smoking is
              prohibited in all parts of the property except in designated guest
              rooms/suites and demarcated smoking areas.
            </Typography>

            {/* Child Policy */}
            <Typography variant="h6" className="label">
              CHILD POLICY
            </Typography>
            <Typography variant="body2" gutterBottom>
              Up to two children below the age of 6 years can stay on a
              complimentary basis, inclusive of breakfast, in the same
              room/suite. One additional adult could stay on a complimentary
              basis, exclusive of breakfast, in the same room/suite.
            </Typography>

            {/* Check-in/Check-out Policy */}
            <Typography variant="h6" className="label">
              CHECK-IN/CHECK-OUT POLICY
            </Typography>
            <Typography variant="body2" gutterBottom>
              Our check-in time is 01:00 PM and our check-out time is 11:00 AM.
              If you want to check in early or sleep in and check out late, we
              will be happy to oblige, subject to availability and at a nominal
              additional charge.
            </Typography>

            {/* Guarantee and Cancellation Policy */}
            <Typography variant="h6" className="label">
              GUARANTEE AND CANCELLATION POLICY
            </Typography>
            <Typography variant="body2" gutterBottom>
              For all bookings, including Infinity Rewards (other than
              non-cancellable/non-amendable/complimentary reservations),
              cancellations/amendments must be made as per the policy stated in
              the last point, to avoid a one-night cancellation fee plus taxes;
              for reward bookings, this cancellation fee will be redeemed via
              coins. The same is applicable in the case of a no-show.
            </Typography>
            <Typography variant="body2" gutterBottom>
              In case of an early check-out, the full amount for the remaining
              length of stay, including taxes, will be charged.
            </Typography>
            <Typography variant="body2" gutterBottom>
              For this reservation, cancellations/amendments are allowed till
              2:00 PM, 2 days prior to arrival, to avoid a one-night
              cancellation fee.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-end align-item-center gap-3">
            <div className="btn-wrapper ">
              <button
                className="cmn-btn btn-bg-1 btn-small"
                onClick={handleClosePopup}
              >
                Cancel Booking
              </button>
            </div>
            <div className="btn-wrapper ">
              <span
                className="cmn-btn btn-bg-2 btn-small"
                onClick={handleConfirmBooking}
              >
                Confirm Booking
              </span>
            </div>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {errorMessage ? errorMessage : "Payment could not be processed!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage ? (
              errorMessage
            ) : (
              <>
                Please try again or wait a moment and try later.
                <br />
                We apologize for the inconvenience.
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsError(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isValidate}
        onClose={handleCloseValidation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Required fields are missing!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Some required fields are missing. Please fill in all the required
            fields before submitting.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidation}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CheckoutForm;
