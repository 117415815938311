// axiosConfig.js

import axios from "axios";
// import setHeader from "./axiosHeader";

// axios.defaults.headers.common["Authorization"] = setHeader();

const baseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: `${baseUrl}`,
});

export default instance;
