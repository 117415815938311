import React, { useRef, useState } from "react";

import event1 from "../assets/img/events/image1.webp";
import event2 from "../assets/img/events/image2.webp";
import event3 from "../assets/img/events/image3.png";
import event4 from "../assets/img/events/image4.png";
import event5 from "../assets/img/events/image5.png";
import UserForm from "./BookingForm/UserForm";
import HotelBanner from "./Hotel/HotelBanner";
import EventBannerImage from "../assets/img/banner/event-banner.png";
import EventBannerMobile from "../assets/img/banner/event-banner-mobile.png";
import AttractionSection from "./Hotel/ArractionSection";

const Events = () => {
  const divRefs = useRef([]);

  // Handle Popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const events = [
    {
      index: 1,
      title: "Personalised, unforgettable events",
      image: event1,
      description:
        "Eco Hotels offers personalized event solutions with a focus on sustainability, ensuring that every gathering, whether intimate or grand, aligns with environmentally responsible practices. By incorporating eco-friendly materials, energy-efficient lighting, and minimal waste policies, each event is designed to reduce its environmental footprint. From corporate conferences, weddings, and private parties to themed celebrations, the Eco Hotels’ venues provide customizable spaces equipped with modern amenities, all while prioritizing sustainability. The hotel's banquet halls, open-air venues, and rooftop spaces are perfect for a variety of events, offering guests a luxurious yet eco-conscious experience tailored to their needs.",
      distance: "N/A",
      time: "N/A",
    },
    {
      index: 2,
      title: "Conference Hall",
      image: event2,
      description:
        "The banquet halls at Eco Hotels provide a perfect venue for hosting conferences and meetings, offering state-of-the-art facilities combined with eco-friendly practices. Equipped with modern audiovisual systems, high-speed Wi-Fi, and comfortable seating, the halls cater to both small and large corporate gatherings. For conferences, we can arrange custom layouts, professional tech support, catering services featuring organic and locally-sourced ingredients, and green event management to ensure a minimal environmental impact. This makes Eco Hotels a prime choice for businesses looking to host impactful events while maintaining an eco-conscious approach.",
      distance: "N/A",
      time: "N/A",
    },
    {
      index: 3,
      title: "Board Room",
      image: event3,
      description:
        "The boardrooms at Eco Hotels are designed to offer a sophisticated and eco-friendly environment for professional meetings. Equipped with the latest audiovisual technology, high-speed Wi-Fi, and ergonomic seating, the boardroom provides everything needed for productive discussions. For meetings, eco-friendly arrangements include offering locally-sourced refreshments, biodegradable stationery, and water-saving fixtures. These thoughtful touches ensure that each meeting maintains a high level of professionalism while minimizing its environmental impact, making it the perfect space for businesses that prioritize sustainability.",
      distance: "N/A",
      time: "N/A",
    },
    {
      index: 4,
      title: "Weddings",
      image: event4,
      description:
        "At Eco Hotels, weddings are celebrated with a blend of luxury and sustainability, offering personalized services that cater to every couple's unique vision. The hotel’s spacious banquet halls create the perfect backdrop for both intimate and grand ceremonies. What sets Eco Weddings apart is the emphasis on eco-friendly arrangements—energy-efficient lighting, biodegradable decor, and locally-sourced, organic catering that supports the environment without compromising on elegance. Eco Hotels ensures that every detail of the event is both luxurious and environmentally responsible, making it an ideal choice for couples who want a beautiful yet earth-friendly celebration.",
      distance: "N/A",
      time: "N/A",
    },
    {
      index: 5,
      title: "Parties",
      image: event5,
      description:
        "Eco Hotels offers a vibrant and sustainable setting for hosting a wide variety of parties, including birthdays, anniversaries, and social gatherings. Arrangements at Eco Hotels prioritize eco-friendly elements, such as energy-efficient lighting, sustainable decor made from natural or recyclable materials, and locally-sourced, organic catering options. Digital invitations and waste-reduction strategies are also encouraged to minimize the environmental impact. With personalized event planning, customizable menus, and modern amenities, Eco Hotels provides a luxurious and eco-conscious environment for unforgettable celebrations.",
      distance: "N/A",
      time: "N/A",
    },
  ];

  return (
    <>
      <HotelBanner
        image={EventBannerImage}
        image_mobile={EventBannerMobile}
        type="event"
      />
      <section className="hotel-details-area section-bg-2 pat-20 pab-50">
        <div className="container">
          {events.map((attraction, idx) => (
            <AttractionSection
              key={idx}
              index={attraction.index}
              title={attraction.title}
              image={attraction.image}
              description={attraction.description}
              distance={attraction.distance}
              time={attraction.time}
              isReverse={idx % 2 !== 0}
            />
          ))}
        </div>
        {isPopupOpen && <UserForm onClose={handleClosePopup}></UserForm>}
      </section>
    </>
  );
};

export default Events;
