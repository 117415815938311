import React from "react";
import hotel1 from "../../assets/img/hotel/hotel.png";
import { Link } from "react-router-dom";

const HotelCard = ({
  type,
  name,
  thumbnail,
  address,
  caclPrice,
  url,
  EcoLogo,
  amenities,
}) => {
  return (
    <div className="hotel-view bg-white radius-20">
      <Link to={`${url}`} className="">
        <img src={thumbnail} alt={name} />
      </Link>
      <div className="hotel-view-contents">
        <div className="hotel-view-contents-header">
          {/* <span className="hotel-view-contents-review">
            {" "}
            <i className="las la-star"></i> 4.5{" "}
            <span className="hotel-view-contents-review-count"> (380) </span>{" "}
          </span> */}
          <h3 className="hotel-view-contents-title d-flex justify-content-between align-items-center">
            <Link to={url}>{name}</Link>
            <img src={EcoLogo} alt="Eco" width={142} />
          </h3>
          <div className="hotel-view-contents-location mt-2">
            <span className="hotel-view-contents-location-icon">
              {" "}
              <i className="las la-map-marker-alt"></i>{" "}
            </span>
            <span className="hotel-view-contents-location-para">{address}</span>
          </div>
        </div>
        <div className="hotel-view-contents-middle">
          <div className="hotel-view-contents-flex">
            {amenities?.map((item, index) => (
              <div
                key={index}
                className="hotel-view-contents-icon myTooltip"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={item.name}
              >
                <i className={`las ${item.icon}`}></i>
              </div>
            ))}

            {/* <div
              className="hotel-view-contents-icon myTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Car Parking"
            >
              <i className="las la-parking"></i>
            </div>
            <div
              className="hotel-view-contents-icon myTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Free Wifi"
            >
              <i className="las la-wifi"></i>
            </div>
            <div
              className="hotel-view-contents-icon myTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Free Breakfast"
            >
              <i className="las la-coffee"></i>
            </div>
            <div
              className="hotel-view-contents-icon myTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Home Service"
            >
              <i className="las la-quidditch"></i>
            </div>
            <div
              className="hotel-view-contents-icon myTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Home Service"
            >
              <i className="las  la-tv"></i>
            </div>

            <div
              className="hotel-view-contents-icon myTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Reception"
            >
              <i className="las la-receipt"></i>
            </div> */}
          </div>
        </div>
        <div className="hotel-view-contents-bottom">
          <div className="hotel-view-contents-bottom-flex">
            <div className="hotel-view-contents-bottom-contents">
              <h4 className="hotel-view-contents-bottom-title">
                {" "}
                {/* {caclPrice} <sub>/Night</sub>{" "} */}
              </h4>
            </div>
            <div className="btn-wrapper">
              <Link to={url} className="cmn-btn btn-bg-1 btn-small">
                {type === "reserve" ? "Reserve Now" : "View Details"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCard;
