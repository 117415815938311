import hoteld1 from "../assets/img/sindhudurg/hotel1.png";
import hoteld2 from "../assets/img/sindhudurg/hotel2.png";
import hoteld3 from "../assets/img/sindhudurg/hotel3.png";
import living from "../assets/img/hotel/bed-1.png";
import dining from "../assets/img/hotel/dining.jpeg";
import attraction from "../assets/img/hotel/attraction.png";
import summerOffer from "../assets/img/banner/summerOffer.jpg";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import HotelTab from "./Hotel/HotelTab";
import MapImage from "../assets/img/hotel/map.png";
import SearchBox from "./Hotel/SearchBox";
import ProfileCard from "./Hotel/ProfileCard";
import { Link } from "react-router-dom";
import EcoLogo from "../assets/img/hotel/eco.png";
import nearby1 from "../assets/img/lonavala/nearby1.png";
import nearby2 from "../assets/img/lonavala/nearby2.png";
import nearby3 from "../assets/img/lonavala/nearby3.png";
import nearby4 from "../assets/img/lonavala/nearby4.png";

const livingData = [
  {
    tab: "Deluxe Rooms",
    content: `The Eco Satva Lonavala offers a luxurious and eco-friendly stay with a variety of well-appointed rooms designed for comfort and sustainability. Each room is thoughtfully crafted to provide a serene environment while minimizing environmental impact. Whether you’re visiting for a relaxing getaway or a corporate retreat, the accommodations are sure to meet your needs.`,
  },
  {
    tab: "Premium Suites",
    content: "This is the Premium Room.",
  },

  {
    tab: "Family Suite",
    content: "This is the Family Room.",
  },
];

const diningData = [
  {
    tab: "Restaurant",
    content:
      "The hotel offers a unique dining experience with a focus on locally sourced ingredients and sustainable practices. Guests can enjoy delicious meals while knowing their choices have a minimal environmental impact.",
  },
  {
    tab: "Bar",
    content: "",
  },
];

const eventData = [
  {
    tab: "Banquet 1",
    content:
      "The Eco Satva Lonavala offers two elegant and spacious banquet halls, perfect for hosting a wide range of events, from weddings and social gatherings to corporate meetings and conferences. Both banquet halls are designed with modern amenities, eco-conscious décor, and customizable options to ensure a seamless and memorable experience for guests.",
  },
  {
    tab: "Banquet 2",
    content:
      "The Eco Satva Lonavala offers two elegant and spacious banquet halls, perfect for hosting a wide range of events, from weddings and social gatherings to corporate meetings and conferences. Both banquet halls are designed with modern amenities, eco-conscious décor, and customizable options to ensure a seamless and memorable experience for guests.",
  },
];

function LonavalaHotelDetails() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const divRefs = useRef([]);

  const sections = [
    { id: 1, label: "Living", image: living, type: "living", data: livingData },
    { id: 2, label: "Dining", image: dining, type: "dining", data: diningData },
    {
      id: 3,
      label: "Event",
      image: attraction,
      type: "event",
      data: eventData,
    },
    { id: 4, label: "Nearby Attractions" },
  ];

  // Function to scroll to a specific div
  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const setting2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024, // Large devices, like laptops and desktops
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium devices, like tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Small devices, like smartphones
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewSetting = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 1500,
    swipeToSlide: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
  };
  return (
    <>
      {/* <TopBanner /> */}
      <section className="hotel-details-area section-bg-2 pat-120 pab-50">
        <div className="container">
          <SearchBox />

          <div className="row g-4 pat-20">
            <div className="col-xl-9 col-lg-7">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  <div className="details-contents-header">
                    <div className="main">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider) => (sliderRef1 = slider)}
                        arrows={false}
                        className="slider slide-show"
                      >
                        <div className="bg-image radius-5">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld1}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld2}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld3}
                            alt=""
                          />
                        </div>
                      </Slider>
                      <Slider
                        asNavFor={nav1}
                        ref={(slider) => (sliderRef2 = slider)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                        {...settings}
                        className="global-slick-init attraction-slider nav-style-one nav-color-one"
                      >
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld1}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld2}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld3}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>

                  <div className="hotel-view-contents">
                    <div className="hotel-view-contents-header">
                      <div className="d-flex align-items-center justify-content-between par-20">
                        <h3 className="fw-bold ">The Eco Satva Lonavala</h3>
                        <img src={EcoLogo} alt="Eco Logo" width={130} />
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-map-marker-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                           hotel near city mall and next to Resonance main
                          building
                        </span>
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-phone-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          +91 4512123456
                        </span>
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-at"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          xyzhotel@hotel.co.in
                        </span>
                      </div>
                    </div>
                    <div className="hotel-view-contents-middle">
                      <div className="hotel-view-contents-flex">
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-parking"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Parking{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-wifi"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Wifi{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-coffee"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Breakfast{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-quidditch"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Room Service{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-swimming-pool"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Pool{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-receipt"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Reception{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-dumbbell"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Gym{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="hotel-view-contents-bottom">
                      <div className="pat-30 par-20">
                        <h5 className="fw-bold label">About</h5>
                        <p className="pat-5">
                          Nestled in the scenic beauty of the Western Ghats, The
                          Eco Satva Lonavala is a luxurious eco-friendly hotel
                          offering a perfect blend of modern comfort and
                          sustainability. Located in the popular hill station of
                          Lonavala, the hotel promises a serene stay with a
                          focus on minimizing environmental impact.
                        </p>
                      </div>
                      {/* Top Navigation */}
                      <ul className="nav nav-pills my-4 gap-4 d-flex justify-content-center">
                        {sections?.map((item, index) => (
                          <li className="nav-item" key={item?.id}>
                            <div className="">
                              <span
                                className={`hotel-btn-wrapper hotel-btn ${
                                  index === 0 ? "active" : ""
                                }`}
                                onClick={() => scrollToDiv(index)}
                              >
                                {item?.label}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {sections
                        .map((section, index) => (
                          <div
                            key={section.id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            <HotelTab
                              title={section.label}
                              image={section.image}
                              type={section.type}
                              tab={true}
                              data={section.data}
                            />
                          </div>
                        ))
                        ?.slice(0, 3)}

                      {/* <HotelTab title="Dining" image={dining} type={"dining"} />
                      <HotelTab title="Events" image={attraction} /> */}
                      <section
                        className="attraction-area"
                        ref={(el) => (divRefs.current[3] = el)}
                      >
                        <div className="">
                          <div className="row mt-5">
                            <h4
                              className="hotel-view-contents-bottom-title"
                              style={{
                                fontSize: "18px",
                              }}
                            >
                              Nearby Attractions
                            </h4>
                            <div className="col-12 mt-3">
                              <Slider
                                className="global-slick-init attraction-slider nav-style-one nav-color-two"
                                {...setting2}
                              >
                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{ width: "90%" }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby1} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Rajmachi Fort
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Lonavala
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby2} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Karla Caves
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Lonavala
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby3} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Tiger’s Leap
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Lonavala
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/NearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby4} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Lonavala Lake
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Lonavala
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-dropdown custom-form">
                      <form action="#">
                        <div className="single-input">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check In{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check in"
                            value="06-08-2024 12:00"
                          />
                          <input
                            className="form--control date-picker form-control input"
                            placeholder="Check in"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          />
                        </div>
                        <div className="single-input mt-3">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check Out{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check out"
                            value="07-08-2024 12:00"
                          />
                          <input
                            className="form--control date-picker form-control input"
                            placeholder="Check out"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="details-sidebar-quantity pt-4">
                      <div className="details-sidebar-quantity-flex">
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Guest{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="5"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Children{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="3"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Room{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="2"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper mt-4">
                      <a
                        href="checkout.html"
                        className="cmn-btn btn-bg-1 btn-small"
                      >
                        Reserve Now
                      </a>
                    </div>
                  </div>
                </div>

                <p className="label">View in map</p>
                <img src={MapImage} alt="map" />

                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10 my-4">
                  <p className="label mb-2 fw-bold">
                    Winter Wonderland Offer at The Eco Satva Kota
                  </p>
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer center-text radius-10">
                      <div className="details-sidebar-offer-thumb">
                        <img src={summerOffer} alt="" />
                      </div>
                      <div className="btn-wrapper mt-5">
                        <a
                          href="javascript:void(0)"
                          className="cmn-btn btn-bg-white"
                        >
                          {" "}
                          Book Now{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <div className="bg-white radius-20 p-4">
                    <p className="label fw-bold">Review</p>
                    <Slider
                      arrows={false}
                      className="slider slide-show"
                      {...reviewSetting}
                    >
                      {[1, 2, 3, 4]?.map((i) => (
                        <ProfileCard key={i} />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LonavalaHotelDetails;
