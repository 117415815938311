import { createSlice } from "@reduxjs/toolkit";


const initialState = {};

const searchSlice = createSlice({
  name: "searchEngine",
  initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.location = action.payload.location;
      state.checkIn = action.payload.checkIn;
      state.checkOut = action.payload.checkOut;
      state.person = action.payload.person;
      state.children = action.payload.children;
      state.room = action.payload;
    },
  },
});

export const { setSearchData } = searchSlice.actions;

export default searchSlice.reducer;
