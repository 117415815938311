import React from "react";

const HotelCardSkeleton = () => {
  return (
    <>
      <div className="col-md-6">
        <div className="card" aria-hidden="true">
          {/* Image Placeholder */}
          <svg
            className="bd-placeholder-img card-img-top"
            width="100%"
            height="180"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder: Image"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
            <title>Placeholder Image</title>
            <rect width="100%" height="100%" fill="#868e96"></rect>
          </svg>

          <div className="card-body">
            {/* Title Placeholder */}
            <h5 className="card-title placeholder-glow">
              <span className="placeholder col-6"></span>
            </h5>

            {/* Text Placeholders */}
            <p className="card-text placeholder-glow">
              <span className="placeholder col-7"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-6"></span>
              <span className="placeholder col-8"></span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelCardSkeleton;
