import DestinationLeft from "./DestinationLeft";
import DestinationRight from "./DestinationRight";
import HotelBanner from "./Hotel/HotelBanner";

function Hotel() {
  return (
    <>
      <HotelBanner />
      <section className="hotel-list-area section-bg-2 pat-100 pab-50">
        <div className="container">
          <div class="section-title center-text">
            <h2 class="title"> Hotel List </h2>
            <div class="section-title-shapes"> </div>
          </div>
          <div className="shop-contents-wrapper mt-5">
            <div className="shop-icon">
              <div className="shop-icon-sidebar">
                <i className="las la-bars"></i>
              </div>
            </div>
            <DestinationLeft />
            <DestinationRight page="hotel-landing" url={"/HotelDetails"} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Hotel;
