import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { settings1 } from "../Home/Slider";

const Carousel = (props) => {
  const { images } = props;
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  return (
    <div className="details-contents-header">
      <div className="main">
        <Slider
          asNavFor={nav2}
          ref={(slider) => (sliderRef1 = slider)}
          arrows={false}
          className="slider slide-show"
        >
          {images.map((image, index) => (
            <div key={index} className="bg-image radius-5">
              <img
                className="img-fluid radius-5"
                style={{ minWidth: "100%", minHeight: "100%" }}
                src={image.img}
                alt=""
              />
            </div>
          ))}
        </Slider>
        <Slider
          asNavFor={nav1}
          ref={(slider) => (sliderRef2 = slider)}
          slidesToShow={3}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={false}
          {...settings1}
          className="global-slick-init attraction-slider nav-style-one nav-color-one"
        >
          {images.map((image, index) => (
            <>
              <div key={index} className="custom-details-contents-header-thumb">
                <img
                  src={image.img}
                  alt="img"
                  style={{
                    minWidth: "100%",
                    minHeight: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
