import kotaPalace from "../../assets/img/kota/nearby/barrage.webp";
import JagmandirPalace from "../../assets/img/kota/nearby/jagmandir.webp";
import OxyPark from "../../assets/img/kota/nearby/Kota Oxy Park_2_11zon.webp";
import gradiaPalace from "../../assets/img/hotel/gradia.png";
import city1 from "../../assets/img/kota/nearby/city1.webp";
import SevenWonders from "../../assets/img/kota/nearby/seven-wonders.png";
import AbhedaMahal from "../../assets/img/kota/nearby/abheda.png";
import GodawariDham from "../../assets/img/kota/nearby/godawari.webp";
import KishoreSagar from "../../assets/img/kota/nearby/kishore-sagar.webp";
import RaoMadhoSingh from "../../assets/img/kota/nearby/rao.png";
import DarrahNational from "../../assets/img/kota/nearby/darrah.webp";
import KhadeGanesh from "../../assets/img/kota/nearby/khade.webp";
import KotaFort from "../../assets/img/kota/nearby/kota-fort.webp";
import KotaRiverfront from "../../assets/img/kota/nearby/Kota RiverFront_1_11zon.webp";

export const kotaNearbyContent = [
  {
    title: "City Palace",
    description:
      "This magnificent 17th-century palace overlooks the Chambal River and is a stunning example of Rajput architecture. The palace houses the Rao Madho Singh Museum, which displays a vast collection of royal artifacts, manuscripts, and weapons. It’s a must-visit for history lovers.",
    distance: "5 km",
    time: "15 minutes by car",
    image: city1,
  },
  {
    title: "Kota Barrage",
    description:
      "The Kota Barrage controls the flow of the Chambal River and is an engineering marvel. It’s a popular spot to witness the beauty of the river and watch the sunset.",
    distance: "Not specified",
    time: "15 minutes by car",
    image: kotaPalace,
  },
  {
    title: "Jagmandir Palace",
    description:
      "Perched on an island in Kishore Sagar Lake, Jagmandir Palace is a beautiful structure built by the royals of Kota. The palace offers a tranquil environment and picturesque views, especially during sunset.",
    distance: "5 km",
    time: "15 minutes by car",
    image: JagmandirPalace,
  },
  {
    title: "Garadia Mahadev Temple",
    description:
      "Offering breathtaking views of the Chambal River from a cliff, this temple is a sacred spot dedicated to Lord Shiva. The panoramic view of the river and gorge is perfect for nature lovers and those seeking serenity.",
    distance: "Not specified",
    time: "15 minutes by car",
    image: gradiaPalace,
  },

  {
    title: "Seven Wonders Park",
    description:
      "Seven Wonders Park in Kota is a unique attraction that features replicas of the world’s most famous monuments, including the Eiffel Tower, the Taj Mahal, and the Statue of Liberty. The park is beautifully landscaped, making it a great place for families, couples, and tourists to enjoy a leisurely walk while exploring these iconic structures.",
    distance: "7 km",
    time: "15 minutes by car",
    image: SevenWonders,
  },

  {
    title: "Abheda Mahal",
    description:
      "Abheda Mahal is a serene and beautiful palace located by the Abheda Lake. Once a hunting lodge for the royal family of Kota, this historical site offers a picturesque view with its architectural beauty and the peaceful surroundings of the lake. The palace is also a great spot for birdwatching, especially during the migratory season. Visitors can enjoy the tranquility of the palace while taking in its historical significance.",
    distance: "12 km",
    time: "30 minutes by car",
    image: AbhedaMahal,
  },

  {
    title: "Godawari Dham Temple",
    description:
      "Godawari Dham Temple is a prominent religious site located on the banks of the Chambal River in Kota. Dedicated to Lord Hanuman, the temple attracts numerous devotees, especially on Tuesdays and Saturdays. The peaceful surroundings and the riverbank location make it a serene place for prayer and meditation. The temple is also known for its beautifully carved structures and spiritual significance in the region.",
    distance: "10 km",
    time: "30 minutes by car",
    image: GodawariDham,
  },

  {
    title: "Kishore Sagar",
    description:
      "Kishore Sagar is a picturesque artificial lake located in the heart of Kota. Built in the 14th century by Prince Dher Deh of Bundi, the lake is a serene spot for leisurely boat rides and evening strolls. The centerpiece of the lake is the Jagmandir Palace, a stunning palace that appears to float on the water. With its tranquil waters and scenic surroundings, Kishore Sagar offers a peaceful escape from the bustling city and is an ideal spot for photography and relaxation.",
    distance: "7 km",
    time: "15 minutes by car",
    image: KishoreSagar,
  },

  {
    title: "Rao Madho Singh Museum",
    description:
      "Rao Madho Singh Museum, located within the historical Kota Garh (City Palace), is a treasure trove of royal artifacts, ancient sculptures, and exquisite miniature paintings. The museum showcases the rich heritage and culture of the Kota region, with collections ranging from royal armory, manuscripts, and murals to beautiful Rajput architecture. It offers a glimpse into the grand lifestyle of the rulers of Kota and the art that flourished under their reign.",
    distance: "7 km",
    time: "15 minutes by car",
    image: RaoMadhoSingh,
  },

  {
    title: "Darrah National Park",
    description:
      "Darrah National Park, also known as Mukundra Hills National Park, is a scenic wildlife sanctuary nestled in the hills near Kota. The park is home to a variety of wildlife, including leopards, wolves, sloth bears, chinkaras, and many bird species. With its dense forests, rivers, and hilly terrain, it is an ideal spot for nature enthusiasts and wildlife lovers. Visitors can enjoy jungle safaris, trekking, and birdwatching in this beautiful natural environment.",
    distance: "50 km",
    time: "1 hour by car",
    image: DarrahNational,
  },

  {
    title: "Khade Ganesh Ji Temple",
    description:
      "Khade Ganesh Ji Temple is a unique and ancient temple in Kota, dedicated to Lord Ganesha. The temple is distinct because it features a standing idol of Lord Ganesha, unlike the traditional seated pose seen in most temples. Situated amidst peaceful surroundings, this temple is a popular pilgrimage site for devotees, particularly during festivals like Ganesh Chaturthi. The temple's serene ambiance and its cultural significance make it a must-visit for spiritual seekers.",
    distance: "9 km",
    time: "20 minutes by car",
    image: KhadeGanesh,
  },

  {
    title: "Oxygen Park",
    description:
      "Oxygen Park is a refreshing green space in Kota, designed to provide a peaceful environment for relaxation and rejuvenation. Known for its well-maintained gardens, walking tracks, and ample green cover, the park is an ideal spot for morning walks, yoga, and picnics. It promotes the concept of eco-friendly living and sustainable urban spaces, making it a favorite among locals for unwinding amidst nature. The park’s focus on environmental well-being aligns with Kota’s efforts towards greener urban planning.",
    distance: "6 km",
    time: "15 minutes by car",
    image: OxyPark,
  },

  {
    title: "Kota Fort",
    description:
      "Kota Fort, also known as Kota Garh, is a significant historical monument in Kota, Rajasthan. Built in the 13th century, this majestic fort stands as a testament to the architectural brilliance of the Rajput era. It houses several palaces and temples within its premises, each adorned with beautiful frescoes and intricate carvings. The fort offers a glimpse into the rich cultural heritage of Rajasthan and is a must-visit for history enthusiasts.",
    distance: "10 km",
    time: "20 minutes by car",
    image: KotaFort,
  },

  {
    title: "Kota Riverfront",
    description:
      "Kota Riverfront, situated along the banks of the Chambal River, is a serene and picturesque spot in Kota, Rajasthan. The area is beautifully landscaped with lush greenery, walking paths, and seating areas, making it an ideal location for a relaxing evening stroll or a peaceful time by the river. The riverfront is also home to a range of recreational activities and is a popular spot for locals and tourists alike. ",
    distance: "12 km",
    time: "25 minutes by car",
    image: KotaRiverfront,
  },
];
