import Hotel1 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img1.webp";
import Hotel2 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img2.webp";
import Hotel3 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img3.webp";
import Hotel4 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img4.webp";
import Hotel5 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img5.webp";
import Hotel6 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img6.webp";
import Hotel7 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img7.webp";
import Hotel8 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img8.webp";
import Hotel9 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img9.webp";
import Hotel10 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img10.webp";
import Hotel11 from "../../assets/img/kochi/Kochi images/kochi 16-11/11zon_compressed/img11.webp";

export const LivingContent = (content) => {
  return (
    <>
      <p>{content}</p>

      <div className="pat-10">
        <div className="hotel-view-contents-flex">
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-parking"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-wifi"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-coffee"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-quidditch"></i>{" "}
            </span>
          </div>

          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-receipt"></i>{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div>
          <h6 className="label">12000/Night</h6>
        </div>
        {/* <div className="btn-wrapper par-20">
          <button className="cmn-btn btn-bg-1 btn-small">
            Book Now
            <span>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1.6em"
                width="1.6em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path>
              </svg>
            </span>
          </button>
        </div> */}
      </div>
    </>
  );
};

export const kochiHotelImageList = [
  { id: 1, img: Hotel1 },
  { id: 2, img: Hotel2 },
  { id: 3, img: Hotel3 },
  { id: 8, img: Hotel8 },
  { id: 10, img: Hotel10 },
  { id: 5, img: Hotel5 },
  { id: 7, img: Hotel7 },
  { id: 4, img: Hotel4 },
  { id: 11, img: Hotel11 },
];
