import Offers1 from "../assets/img/single-page/infinity_banner_pre-sign-in-dstp.jpg";
import Offers2 from "../assets/img/single-page/sunday-redefined.jpg";
import Offers3 from "../assets/img/single-page/happy-hour.jpg";
import Offers4 from "../assets/img/single-page/Day-Cation-616x225.jpg";
import Offers5 from "../assets/img/single-page/spykar_bags4.jpg";
import Offers6 from "../assets/img/single-page/Healthians_1.jpg";
import HappyHour from "../assets/img/single-page/happyhour.jpg";
import Staycation from "../assets/img/single-page/staycation.png";
import TopBanner from "./Banner";
import { Link } from "react-router-dom";

function PromotionOffer() {
  return (
    <>
      <TopBanner />
      <section className="attraction-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Offers & Promotions</h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row g-4 mt-4">
            {/* <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    {" "}
                    <img src={Offers1} alt="img" />{" "}
                  </a>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    {" "}
                    <a href="#"> Member’s rate </a>{" "}
                  </h4>
                </div>
              </div>
            </div> */}
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <span className="gallery-popup-two">
                    <img src={Staycation} alt="img" />
                  </span>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    <Link to="/PromotionOffer">
                      Explore Staycation Packages
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <span className="gallery-popup-two">
                    <img src={HappyHour} alt="img" />
                  </span>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    Enjioy the Discounts on our Happy Hour offer
                  </h4>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    {" "}
                    <img src={Offers4} alt="img" />{" "}
                  </a>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    {" "}
                    <a href="#"> Day Rooms ( 10am to 5pm ) </a>{" "}
                  </h4>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="section-title center-text mt-5">
            <h2 className="title">Partners Offers</h2>
            <div className="section-title-shapes"> </div>
            <p className="section-para">
              {" "}
              Enjoy a wide range of benefits thanks to our partner's exclusive
              offers and services. Join our loyalty program today and discover
              an all-new world of personalised offers and exclusive discounts
              tailored to your needs.{" "}
            </p>
          </div>
          <div className="row g-4 mt-4">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    {" "}
                    <img src={Offers5} alt="img" />{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    {" "}
                    <img src={Offers6} alt="img" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default PromotionOffer;
