import React, { useEffect, useRef, useState } from "react";
import SearchBox from "./SearchBox";
import Slider from "react-slick";
// import hoteld1 from "../../assets/img/kota/nearby/nearby1.png";
import hoteld2 from "../../assets/img/kota/nearby/Fort-Kota_1_11zon.webp";
import hoteld3 from "../../assets/img/kota/nearby/Kota RiverFront (1)_3_11zon.webp";
import hoteld4 from "../../assets/img/kota/nearby/Kota Oxy Park (1)_2_11zon.webp";
import food1 from "../../assets/img/hotel/peda.webp";
import food2 from "../../assets/img/hotel/food2.png";
import food3 from "../../assets/img/hotel/food3.png";
import food4 from "../../assets/img/hotel/food4.png";
import culture from "../../assets/img/hotel/culture.png";

import travel from "../../assets/img/hotel/travel.png";
import sunnySvg from "../../assets/img/hotel/sunny.svg";
import rainySvg from "../../assets/img/hotel/rainy.svg";
import snowySvg from "../../assets/img/hotel/weather_snowy.png";
import weather from "../../assets/img/kota/nearby/weather.png";
import HotelTab from "./HotelTab";
import { kotaNearbyContent } from "../content/KotaNearbyContent";
import AttractionSection from "./ArractionSection";
import { settings1 } from "../Home/Slider";
import {
  Typography,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";

const cultureContent = (
  <>
    <p>
      Kota is deeply rooted in Rajasthani culture, known for its vibrant
      traditions, colorful festivals, and warm hospitality. The city celebrates
      festivals like Diwali, Holi, and the Dussehra Mela, where you can witness
      local customs, music, and dance. The craftsmanship of Kota Doria sarees is
      also world-famous, showcasing the region’s rich textile heritage.
    </p>
    <br />
    <p>
      The population of Kota is approximately 1.2 million, with a blend of
      traditional and modern lifestyles. Visitors will experience the
      friendliness of the locals and their deep respect for culture and history.
    </p>
  </>
);

const travelContent = (
  <>
    <ul className="d-flex flex-column gap-4">
      <li>
        <span className="label">By Air:</span>The nearest airport is Jaipur
        International Airport, about 250 km from Kota. From the airport, you can
        reach the hotel by taxi or train.
      </li>
      <li>
        <span className="label">By Train:</span>Kota is well-connected by rail,
        with Kota Junction being one of the major railway stations in Rajasthan.
        The station is just 7 km from the hotel, making it easy for guests to
        access the property.
      </li>
      <li>
        <span className="label">By Road:</span>Kota has excellent road
        connectivity, with regular bus services from Jaipur, Udaipur, and Delhi.
        The NH52 highway makes road travel convenient and efficient.
      </li>
    </ul>
  </>
);

const NearbyAttraction = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const [value, setValue] = React.useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const divRefs = useRef([]);

  const sections = [
    {
      id: 2,
      label: "Cuture",
      image: culture,
      type: "dining",
      data: [{ content: cultureContent }],
    },
    {
      id: 3,
      label: "Travel",
      image: travel,
      type: "event",
      data: [{ content: travelContent }],
    },
    { id: 4, label: "Food" },
  ];

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    scrollToDiv(newValue);
  };

  return (
    <section className="hotel-details-area section-bg-2 pat-120 pab-50">
      <div className="container">
        <SearchBox />
        <div className="details-contents bg-white radius-10 mt-4">
          <div className="details-contents-header">
            <div className="main">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                arrows={false}
                className="slider slide-show"
              >
                {/* <div className="bg-image radius-5">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld1}
                    alt=""
                  />
                </div> */}
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld2}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld3}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld4}
                    alt=""
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                {...settings1}
                className="global-slick-init attraction-slider nav-style-one nav-color-one"
              >
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld2}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld3}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld4}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Slider>
            </div>

            <div className="hotel-view-contents mt-5">
              <Box p={2}>
                <Typography variant="h5" fontWeight="bold" className="jakarta">
                  Explore the Wonders of Kota, Rajasthan
                </Typography>

                <Box mt={2} textAlign={"left"}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="text.secondary"
                    className="verdana"
                  >
                    Best Time to Visit Kota
                  </Typography>
                  <Box
                    mt={1}
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    gap={1}
                  >
                    <img
                      src={weather}
                      alt="sunny"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1" className="verdana">
                      October - March
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="center"
                  gap={isMobile ? 2 : 5}
                  sx={{
                    // for all children element change font style to verdana
                    "& *": {
                      fontFamily: "Verdana",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={sunnySvg}
                      alt="sunny"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Apr - Jun (35°C to 45°C)
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={rainySvg}
                      alt="rainy"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Jul - Sep (25°C to 35°C)
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={snowySvg}
                      alt="snowy"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Oct - Mar (10°C to 25°C)
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ mt: 2 }} />
              </Box>

              <div className="hotel-view-contents-bottom">
                <div className="pat-30 par-20">
                  <h5 className="fw-bold label">About</h5>
                  <p className="pat-5">
                    Kota, a city that blends historical grandeur with natural
                    beauty, offering a unique cultural experience for every
                    traveler. Situated in the heart of Rajasthan, Kota is
                    renowned for its stunning palaces, temples, gardens, and
                    rich heritage. Whether you're an adventure enthusiast or a
                    history buff, the city has something to offer everyone.
                    <br />
                    <br />
                    The ideal time to visit Kota is during the winter months,
                    from October to March, when the weather is pleasant and
                    suitable for exploring. Temperatures during this time range
                    from 10°C to 25°C, making it perfect for sightseeing and
                    outdoor activities. Summers can be extremely hot, with
                    temperatures reaching up to 45°C, so winter is the
                    recommended travel season.
                  </p>
                </div>
                {/* Top Navigation */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={isMobile ? "start" : "center"}
                  my={4}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{ hidden: true }}
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        gap: { sm: 5 },
                      },
                      "& .MuiTabs-scrollButtons": {
                        display: "flex",
                      },
                      "@media (max-width: 600px)": {
                        "& .MuiTabs-scrollButtons.Mui-disabled": {
                          display: "flex",
                        },
                      },

                      "& .MuiTabs-scrollButtons.Mui-disabled": {
                        display: "flex",
                        opacity: 0.5,
                      },
                    }}
                  >
                    {sections?.map((item, index) => (
                      <Tab
                        key={item.id}
                        label={item.label}
                        onClick={() => scrollToDiv(index)}
                        sx={{
                          borderRadius: "50px",
                          backgroundColor: "transparent",
                          color: "inherit",
                          padding: "8px 36px",
                          minWidth: "auto",
                          transition: "all 0.3s ease",
                          "&.Mui-selected": {
                            border: "1px solid #8b4513",
                            color: "black",
                          },
                          "&:hover": {
                            border: "1px solid #8b4513",
                            color: "black",
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                </Box>
                <div>
                  {sections
                    .map((section, index) => (
                      <div
                        key={section.id}
                        ref={(el) => (divRefs.current[index] = el)}
                      >
                        <HotelTab
                          title={section.label}
                          image={section.image}
                          type={section.type}
                          data={section.data}
                        />
                      </div>
                    ))
                    ?.slice(0, 2)}
                  <div ref={(el) => (divRefs.current[2] = el)}>
                    <h4
                      className="hotel-view-contents-bottom-title"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Food in Kota
                    </h4>
                    <p>
                      Kota offers a delectable array of Rajasthani cuisine, with
                      dishes that reflect the region's rich heritage. Some
                      must-try foods include:
                    </p>

                    <div className="pat-10">
                      <div className="row g-4">
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food1}
                                    alt="Mahaveerji ka Peda"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Mahaveerji ka Peda</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food2}
                                    alt="Dal Baati Churma"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Dal Baati Churma</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food3}
                                    alt="Kota Kachori"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Kota Kachori</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food4}
                                    alt="Ghevar"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Ghevar</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <h4
                        className="hotel-view-contents-bottom-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Top Attractions Near The Eco Satva Kota
                      </h4>

                      <div className="pat-40">
                        {kotaNearbyContent?.map((attraction, idx) => (
                          <AttractionSection
                            key={idx}
                            index={attraction.index}
                            title={attraction.title}
                            image={attraction.image}
                            description={attraction.description}
                            distance={attraction.distance}
                            time={attraction.time}
                            isReverse={isMobile ? null : idx % 2 !== 0}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NearbyAttraction;
