import React, { useEffect, useRef, useState } from "react";
import SearchBox from "../SearchBox";
import Slider from "react-slick";
import hoteld1 from "../../../assets/img/nagpur/nearby2/nearby1.webp";
import hoteld2 from "../../../assets/img/nagpur/nearby2/nearby2.webp";
import hoteld3 from "../../../assets/img/nagpur/nearby2/nearby3.webp";
import hoteld4 from "../../../assets/img/nagpur/nearby2/nearby4.webp";
import hoteld5 from "../../../assets/img/nagpur/nearby2/nearby5.webp";
import food1 from "../../../assets/img/nagpur/nearby/food1.webp";
import food2 from "../../../assets/img/nagpur/nearby/food2.webp";
import food3 from "../../../assets/img/nagpur/nearby/food3.webp";
import food4 from "../../../assets/img/nagpur/nearby/food4.webp";
import culture from "../../../assets/img/nagpur/nearby/culture.webp";
import travel from "../../../assets/img/kochi/nearby/travel.png";
import sunnySvg from "../../../assets/img/hotel/sunny.svg";
import rainySvg from "../../../assets/img/hotel/rainy.svg";
import snowySvg from "../../../assets/img/hotel/weather_snowy.png";
import weather from "../../../assets/img/kota/nearby/weather.png";
import HotelTab from "../HotelTab";
import { NagpurNearbyContent } from "../../content/NagpurNearbyContent";
import AttractionSection from "../ArractionSection";
import { settings1 } from "../../Home/Slider";
import {
  Typography,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";

const cultureContent = (
  <>
    <p>
      Nagpur’s culture is a blend of traditional Maharashtrian influences and
      modern cosmopolitan living. The city is known for its literature,
      performing arts, and festivals such as Ganesh Chaturthi and Makar
      Sankranti. Its close association with Dr. B.R. Ambedkar and the Buddhist
      movement is also reflected in cultural landmarks like Deekshabhoomi.
    </p>
    <br />
    <p>
      Nagpur has a population of approximately 2.9 million. It is one of the
      fastest-growing cities in Maharashtra and is recognized as a commercial
      and political center in Vidarbha. The city’s diverse population comprises
      people from different religious and cultural backgrounds, making it a
      vibrant and cosmopolitan place to live.
    </p>
  </>
);

const travelContent = (
  <>
    <ul className="d-flex flex-column gap-4">
      <li>
        <span className="label">By Air:</span>Dr. Babasaheb Ambedkar
        International Airport connects Nagpur to major cities in India and some
        international destinations.
      </li>
      <li>
        <span className="label">By Train:</span>Nagpur is a key junction in
        Central India with excellent rail connectivity to Mumbai, Delhi,
        Kolkata, and Chennai.
      </li>
      <li>
        <span className="label">By Road:</span>Well-connected via the National
        Highway Network (NH 44, NH 6, NH 7), Nagpur is accessible from all
        corners of the country.
      </li>
    </ul>
  </>
);

const NagpurNearbyAttraction = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const [value, setValue] = React.useState(0);

  const divRefs = useRef([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    scrollToDiv(newValue);
  };

  const sections = [
    {
      id: 2,
      label: "Cuture",
      image: culture,
      type: "dining",
      data: [{ content: cultureContent }],
    },
    {
      id: 3,
      label: "Travel",
      image: travel,
      type: "event",
      data: [{ content: travelContent }],
    },
    { id: 4, label: "Food" },
  ];

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="hotel-details-area section-bg-2 pat-120 pab-50">
      <div className="container">
        <SearchBox />
        <div className="details-contents bg-white radius-10 mt-4">
          <div className="details-contents-header">
            <div className="main">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                arrows={false}
                className="slider slide-show"
              >
                <div className="bg-image radius-5">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld1}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld2}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld3}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld4}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld5}
                    alt=""
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                {...settings1}
                className="global-slick-init attraction-slider nav-style-one nav-color-one"
              >
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld1}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld2}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld3}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld4}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld5}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Slider>
            </div>

            <div className="hotel-view-contents mt-5">
              <Box p={2}>
                <Typography variant="h5" fontWeight="bold">
                  Explore the Orange City,Nagpur
                </Typography>

                <Box mt={2} textAlign={"left"}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="text.secondary"
                  >
                    Best Time to Visit Nagpur
                  </Typography>
                  <Box
                    mt={1}
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    gap={1}
                  >
                    <img
                      src={weather}
                      alt="sunny"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1">October - February</Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="center"
                  gap={isMobile ? 2 : 5}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={sunnySvg}
                      alt="sunny"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Mar - Jun (35°C to 48°C)
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={rainySvg}
                      alt="rainy"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Jul - Sep (24°C to 30°C)
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <img
                      src={snowySvg}
                      alt="snowy"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Oct - Feb (10°C to 25°C)
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ mt: 2 }} />
              </Box>

              <div className="hotel-view-contents-bottom">
                <div className="pat-30 par-20">
                  <h5 className="fw-bold label">About</h5>
                  <p className="pat-5">
                    Nagpur, often referred to as the Orange City due to its
                    renowned orange production, is a vibrant metropolis in the
                    heart of India, situated in the state of Maharashtra. Known
                    for its high-quality oranges, it serves as a major trade hub
                    for this citrus fruit. Nagpur also holds historical and
                    cultural significance, with its origins tracing back to the
                    8th century, when it was founded by the Gond Kings before
                    being ruled by the Marathas in the 18th century. The city
                    played a crucial role during India’s colonial era and its
                    freedom struggle, later becoming the site where Dr. B.R.
                    Ambedkar and millions of his followers embraced Buddhism at
                    Deekshabhoomi, marking the beginning of the Dalit Buddhist
                    movement
                    <br />
                    As the Winter Capital of Maharashtra, Nagpur hosts the
                    state’s annual winter legislative session, emphasizing its
                    political importance. Today, it stands as a thriving center
                    for commerce, industry, and culture in Central India,
                    blending its historical roots with modern growth and
                    progress.
                  </p>
                </div>
                {/* Top Navigation */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={isMobile ? "start" : "center"}
                  my={4}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{ hidden: true }}
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        gap: { sm: 5 },
                      },
                      "& .MuiTabs-scrollButtons": {
                        display: "flex",
                      },
                      "@media (max-width: 600px)": {
                        "& .MuiTabs-scrollButtons.Mui-disabled": {
                          display: "flex",
                        },
                      },

                      "& .MuiTabs-scrollButtons.Mui-disabled": {
                        display: "flex",
                        opacity: 0.5,
                      },
                    }}
                  >
                    {sections?.map((item, index) => (
                      <Tab
                        key={item.id}
                        label={item.label}
                        onClick={() => scrollToDiv(index)}
                        sx={{
                          borderRadius: "50px",
                          backgroundColor: "transparent",
                          color: "inherit",
                          padding: "8px 36px",
                          minWidth: "auto",
                          transition: "all 0.3s ease",
                          "&.Mui-selected": {
                            border: "1px solid #8b4513",
                            color: "black",
                          },
                          "&:hover": {
                            border: "1px solid #8b4513",
                            color: "black",
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                </Box>
                <div>
                  {sections
                    .map((section, index) => (
                      <div
                        key={section.id}
                        ref={(el) => (divRefs.current[index] = el)}
                      >
                        <HotelTab
                          title={section.label}
                          image={section.image}
                          type={section.type}
                          data={section.data}
                        />
                      </div>
                    ))
                    ?.slice(0, 2)}
                  <div ref={(el) => (divRefs.current[2] = el)}>
                    <h4
                      className="hotel-view-contents-bottom-title"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Food in Nagpur
                    </h4>
                    <p>
                      Nagpur offers a blend of Maharashtrian, South Indian, and
                      Central Indian cuisines, making it a food lover's
                      paradise.
                    </p>

                    <div className="pat-10">
                      <div className="row g-4">
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food1}
                                    alt="Appam with Stew"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Saoji Curry</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food2}
                                    alt="Meen Moilee"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Tarri Poha</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food3}
                                    alt="Parotta with Beef Fry"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Zunka Bhakri</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food4}
                                    alt="puttu"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Bhakarwadi</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <h4
                        className="hotel-view-contents-bottom-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Top Attractions Near EcoXpress Satva Nagpur
                      </h4>

                      <div className="pat-40">
                        {NagpurNearbyContent?.map((attraction, idx) => (
                          <AttractionSection
                            key={idx}
                            index={attraction.index}
                            title={attraction.title}
                            image={attraction.image}
                            description={attraction.description}
                            distance={attraction.distance}
                            time={attraction.time}
                            isReverse={idx % 2 !== 0}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NagpurNearbyAttraction;
