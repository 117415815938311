import { configureStore } from "@reduxjs/toolkit";
import searchHotelReducer from "./reducer/searchHotelReducer";
import bookingHotelReducer from "./reducer/bookingHotelReducer";
import bookingBlockReducer from "./reducer/bookingBlockReducer";
import filterHotelReducer from "./reducer/filterHotelReducer";
import priceHotelReducer from "./reducer/PriceHotelReducer";
import propertyReducer from "./reducer/propertyReducer";

const store = configureStore({
  reducer: {
    search: searchHotelReducer,
    booking: bookingHotelReducer,
    bookingBlock: bookingBlockReducer,
    hotel_location: filterHotelReducer,
    priceHotel: priceHotelReducer,
    property: propertyReducer,
  },
});

export default store;
