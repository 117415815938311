import React from "react";
import { useMediaQuery, useTheme, Grid, Typography, Box } from "@mui/material";

const AttractionSection = ({
  index,
  title,
  image,
  description,
  distance,
  time,
  isReverse,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={3}
      className={`mb-4 ${isMobile ? "mt-5" : "mt-0"}`}
      direction={isReverse ? "row-reverse" : "row"}
    >
      {/* Image Section */}
      <Grid item xs={12} md={6} xl={4} container justifyContent="center">
        <Box
          component="img"
          src={image}
          alt="Attraction"
          sx={{
            width: "100%",
            height: { xs: 180, sm: 200, md: 250 }, // Responsive height settings
            maxWidth: 400,
            borderRadius: 2,
            boxShadow: 3,
            objectFit: "cover", // Ensures image fills the Box while maintaining aspect ratio
          }}
        />
      </Grid>

      {/* Content Section */}
      <Grid item xs={12} md={6} xl={8}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          pt={2}
        >
          <Typography
            variant="h6"
            component="h6"
            className="label jakarta"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography variant="body" color="textSecondary" paragraph>
            {description}
          </Typography>
          {distance !== "N/A" && time !== "N/A" && (
            <>
              <Typography variant="body2" color="textSecondary">
                <strong>Distance from Hotel:</strong> {distance}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <strong>Travel Time:</strong> {time}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AttractionSection;
