import React from "react";
import Slider from "react-slick";
import { settings1 } from "../Home/Slider";
import { Link } from "react-router-dom";

const HotelNearby = (props) => {
  const { divRefs, nearby, link } = props;
  return (
    <>
      <section
        className="attraction-area"
        ref={(el) => (divRefs.current[3] = el)}
      >
        <div className="">
          <div className="row my-5">
            <h4
              className="hotel-view-contents-bottom-title"
              style={{
                fontSize: "18px",
              }}
            >
              Nearby Attractions
            </h4>
            <div className="col-12 mt-3">
              <Slider
                className="global-slick-init attraction-slider nav-style-one nav-color-two"
                {...settings1}
              >
                {nearby.map((item, index) => (
                  <div key={index}>
                    <Link to={link}>
                      <div class="attraction-item" style={{ width: "90%" }}>
                        <div class="single-attraction">
                          <div class="single-attraction-thumb">
                            <img
                              src={item?.image}
                              alt={item?.name}
                              style={{
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div class="single-attraction-contents">
                            <h4
                              class="single-attraction-contents-title"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.name}
                            </h4>
                            <p class="single-attraction-contents-para">
                              {item?.location}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HotelNearby;
