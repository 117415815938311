import { Box, Tab, Tabs } from "@mui/material";
import React, { useRef, useState } from "react";
import Slider from "react-slick";

function HotelTab({
  image,
  type,
  title,
  tab,
  data,
  brochureBtn,
  downloadMenuBtn,
  style,
}) {
  const containerRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0); // Track active tab index
  const sliderRef1 = useRef(null); // Ref for the main slider

  // Handle Tab Click
  const handleTabClick = (index) => {
    setActiveTab(index); // Set the active tab index
    if (sliderRef1.current) {
      sliderRef1.current.slickGoTo(index); // Scroll the slider to the selected index
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="mt-5">
      {/* Content Section */}
      <div className="row mb-4">
        <div className="col-md-4 col-xl-3">
          {image && (
            <img
              src={image}
              className="radius-50 w-100"
              alt="Room"
              style={{
                borderRadius: `${
                  style === "oval"
                    ? "140px"
                    : style === "rounded"
                    ? "20px"
                    : "20px"
                }`,
                height: "350px",
                objectFit: "cover",
              }}
            />
          )}
        </div>
        <div
          className={`col-md-8 col-xl-9 d-flex flex-column justify-content-between pt-1 par-20`}
        >
          {/* Content Area */}
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <h4
                className="hotel-view-contents-bottom-title mt-2"
                style={{
                  fontSize: "18px",
                }}
              >
                {title || ""}
              </h4>
              {brochureBtn && (
                <button className="text-gold bg-transparent border-0">
                  Download Brochure
                  <span className="p-1 mt-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1.6em"
                      width="1.6em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path>
                    </svg>
                  </span>
                </button>
              )}

              {downloadMenuBtn && (
                <button className="text-gold bg-transparent border-0">
                  Download Menu
                  <span className="p-1 mt-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1.6em"
                      width="1.6em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path>
                    </svg>
                  </span>
                </button>
              )}
            </div>
            <div className="my-1">
              <Slider
                ref={sliderRef1} // Attach the ref to the Slider component
                {...settings}
              >
                {data?.map((item, index) => (
                  <p key={index}>{item.content}</p>
                ))}
              </Slider>
            </div>
          </div>

          {/* Bottom Tabs for Content Switching */}
          <Box display="flex" alignItems="center">
            {/* Tabs Container */}
            <Box
              ref={containerRef}
              sx={{
                display: "flex",
                overflowX: "auto",
                width: "100%",
                "::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for WebKit browsers
              }}
              className="hide-scrollbar"
            >
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => handleTabClick(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ hidden: true }}
                sx={{
                  "& .MuiTab-root": {
                    color: "var(--brown-color)",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#B8860B",
                    fontWeight: "bold",
                  },
                  "& .MuiTabs-flexContainer": {
                    gap: { sm: 2 },
                  },
                  "& .MuiTabs-scrollButtons": {
                    display: "flex",
                    color: "#B8860B",
                  },
                  "& .MuiTabs-scrollButtons.Mui-disabled": {
                    display: "flex",
                    opacity: 0.5,
                  },
                }}
              >
                {data?.map((item, index) => (
                  <Tab
                    key={index}
                    label={item.tab}
                    onClick={() => handleTabClick(index)}
                    className="verdana"
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1rem",
                      fontWeight: activeTab === index ? "bold" : "normal",
                      cursor: "pointer",
                      borderRadius: 1,
                      padding: "8px 16px",
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default HotelTab;
