import { createSlice } from "@reduxjs/toolkit";

const blockInitialState = {};

const blockSlice = createSlice({
  name: "booking",
  initialState: blockInitialState,
  reducers: {
    setBookingBlockData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setBookingBlockData } = blockSlice.actions;

export default blockSlice.reducer;
